<template>
    <div class="product-container">
        <div class="product-modularTitle-tabs">
            <div class="w12 product-modularTitle">
                <div class="moduular-mcName-box">
                    <h4 class="menkTitle">
                         <br /> <br />
                    </h4>
                    <span>热门产品推荐</span>
                </div>
                <p>基于20年的技术沉淀，保护您在应用系统和数据安全</p>
            </div>
            <!-- 前六个按钮 -->
            <div class="w12 product-tabs-container" v-if="hasNews">
                <div
                    class="product-type-container pointer"
                    v-for="(item, index) in productList"
                    :key="index"
                    @click="changeHotProduct(index, item)"
                >
                    <div
                        class="hotProductImg"
                        :class="`hotProductImg` + (index + 1)"
                        @mouseenter="itemHover($event)"
                        @mouseleave="itemleave($event)"
                    ></div>
                    <div class="product-type-text">
                        <p :class="{ active: index == active }">
                            {{ item.name }}
                        </p>
                        <span :class="{ active: index == active }"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="hotProduct-content-container" v-if="active < 6">
            <!-- 综合推荐 -->
            <div
                class="w12 h100 product-recommend menkOcr"
                v-if="active == 0 && hasReNews"
            >
                <div class="hotProduct-container h100">
                    <div class="hotProduct" v-for="i in 6" :key="i">
                        <img
                            src="~assets/images/hotProducBackImg.png"
                            alt=""
                            class="hotProducBackImg"
                        />
                        <div class="h100 hotProductBox">
                            <div class="product-name">
                                <p>{{ reProductList[i - 1].name }}</p>
                                <span>热力推荐</span>
                            </div>
                            <div class="productIntroduce">
                                {{ reProductList[i - 1].resume }}
                            </div>
                            <div class="hotProductBtnBox">
                                <p
                                    class="useProduct pointer"
                                    @click="useProduct(reProductList[i - 1])"
                                >
                                    立即使用
                                </p>
                                <!-- <p
                                    class="useProduct pointer"
                                    @click="payProduct(item)"
                                    v-if="item.isPurchase == '1'"
                                >
                                    立即购买
                                </p> -->
                                <p
                                    class="productDetails pointer"
                                    @click="enterDetails(reProductList[i - 1])"
                                >
                                    产品详情
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 产品详情 -->
            <div
                class="w12 h100 menkOcr active"
                v-if="active != 0 && hasContent"
            >
                <div class="menkProduct h100">
                    <img
                        src="~assets/images/menkOcr.png"
                        class="menkImg"
                        alt=""
                    />
                    <div class="h100 menk-inContent">
                        <div class="title">{{ proDetails.name }}</div>
                        <div class="menk-inText">
                            {{ proDetails.resume }}
                        </div>
                        <div class="menk-btn">
                            <p
                                class="useProduct pointer"
                                @click="useProduct(proDetails)"
                            >
                                立即使用
                            </p>
                            <p
                                class="file pointer"
                                @click="enterDetails(proDetails)"
                            >
                                查看详情
                            </p>
                        </div>
                    </div>
                </div>
                <div class="productContent h100">
                    <p>应用场景</p>
                    <div
                        class="productContent-text"
                        v-for="item in proDetails.productUseScenariosList"
                        :key="item.id"
                    >
                        <p class="ellipsis">{{ item.name }}</p>
                        <span>{{ item.substance }}</span>
                    </div>
                </div>
                <div class="productContent h100">
                    <p>产品优势</p>
                    <div
                        class="productContent-text"
                        v-for="item in proDetails.productAdvantageInfoList"
                        :key="item.id"
                    >
                        <p class="ellipsis">
                            <span>{{ item.name }}</span>
                        </p>
                        <span>{{ item.substance }}</span>
                    </div>
                </div>
                <div
                    class="productNotice h100"
                    v-if="proDetails.productPaymentRuleList.length == 0"
                >
                    <p>活动&公告</p>
                    <div class="productNotice-list">
                        <p v-if="proDetails.notictInfoList.length == 0">
                            暂无活动
                        </p>
                        <div v-else>
                            <div
                                class="productNotice-li pointer"
                                v-for="i in proDetails.notictInfoList.length"
                                :key="i"
                                @click="
                                    enterNotice(proDetails.notictInfoList[i-1].id)
                                "
                            >
                                <p>最新公告</p>
                                <div class="productNotice-li-text">
                                    {{ proDetails.notictInfoList[i - 1].title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productNotice h100 productPayment" v-else>
                    <img
                        src="~assets/images/indexPaymentBackImg.png"
                        class="indexPaymentBackImg"
                        alt=""
                    />
                    <div class="productPaymentNews">
                        <h4>超值选购</h4>
                        <h6>多款高性价比套餐服务任您选购</h6>
                        <el-form
                            :model="payForm"
                            label-width="66px"
                            size="small"
                        >
                            <el-form-item label="套餐类型:">
                                <el-select
                                    v-model="payForm.packageType"
                                    @change="changePackageType"
                                    v-if="proDetails.ocr"
                                >
                                    <el-option
                                        v-for="index in proDetails
                                            .productPaymentRuleList.length - 1"
                                        :key="index"
                                        :label="
                                            proDetails.productPaymentRuleList[
                                                index
                                            ].name
                                        "
                                        :value="index"
                                    ></el-option>
                                </el-select>
                                <el-select
                                    v-model="payForm.packageType"
                                    @change="changePackageType"
                                    v-else
                                >
                                    <el-option
                                        v-for="(
                                            item, index
                                        ) in proDetails.productPaymentRuleList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="index"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="产品规格:">
                                <span>{{ payForm.specs }}</span>
                            </el-form-item>
                            <el-form-item label="有效时长:">
                                <span>{{ payForm.efficientTime }}</span>
                            </el-form-item>
                            <div class="activityBox">
                                <img
                                    @click="enterWpsActivity"
                                    src="~assets/images/temporary/wpsHdImg.png"
                                    alt=""
                                />
                            </div>
                            <div class="payAdvantage">
                                <p>超值优惠</p>
                                <p>灵活购买</p>
                                <p>急速响应</p>
                            </div>
                            <div class="price">
                                价格:
                                <p>
                                    ¥
                                    <em>{{ payForm.price }}元</em>
                                </p>
                            </div>
                            <div class="payBtn" @click="enterPay">立即购买</div>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        <!-- 后六个按钮 -->
        <div class="product-modularTitle-tabs" v-if="hasNews">
            <div class="w12 product-tabs-container">
                <div
                    class="product-type-container pointer"
                    v-for="(item, index) in productList1"
                    :key="index"
                    @click="changeHotProduct(index + 6, item)"
                >
                    <div
                        class="hotProductImg"
                        :class="`hotProductImg` + (index + 7)"
                        @mouseenter="itemHover($event)"
                        @mouseleave="itemleave($event)"
                    ></div>
                    <div class="product-type-text">
                        <p :class="{ active: index + 6 == active }">
                            {{ productList1[index].name }}
                        </p>
                        <span :class="{ active: index + 6 == active }"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 产品详情 -->
        <div
            class="hotProduct-content-container"
            v-if="active >= 6 && active != 11"
        >
            <div class="w12 h100 menkOcr active" v-if="hasContent">
                <div class="menkProduct h100">
                    <img
                        src="~assets/images/menkOcr.png"
                        class="menkImg"
                        alt=""
                    />
                    <div class="h100 menk-inContent">
                        <div class="title">{{ proDetails.name }}</div>
                        <div class="menk-inText">
                            {{ proDetails.resume }}
                        </div>
                        <div class="menk-btn">
                            <p
                                class="useProduct pointer"
                                @click="useProduct(proDetails)"
                                v-if="proDetails.routerPath"
                            >
                                立即使用
                            </p>
                            <p
                                class="file pointer"
                                @click="enterDetails(proDetails)"
                            >
                                查看详情
                            </p>
                        </div>
                    </div>
                </div>
                <div class="productContent h100">
                    <p>应用场景</p>
                    <div
                        class="productContent-text"
                        v-for="item in proDetails.productUseScenariosList"
                        :key="item.id"
                    >
                        <p class="ellipsis">{{ item.name }}</p>
                        <span>{{ item.substance }}</span>
                    </div>
                </div>
                <div class="productContent h100">
                    <p>产品优势</p>
                    <div
                        class="productContent-text"
                        v-for="item in proDetails.productAdvantageInfoList"
                        :key="item.id"
                    >
                        <p class="ellipsis">
                            <span>{{ item.name }}</span>
                        </p>
                        <span>{{ item.substance }}</span>
                    </div>
                </div>
                <div
                    class="productNotice h100"
                    v-if="proDetails.productPaymentRuleList.length == 0"
                >
                    <p>活动&公告</p>
                    <div class="productNotice-list">
                        <p v-if="proDetails.notictInfoList.length == 0">
                            暂无活动
                        </p>
                        <div v-else>
                            <div
                                class="productNotice-li pointer"
                                v-for="i in proDetails.notictInfoList.length"
                                :key="i"
                                @click="
                                    enterNotice(proDetails.notictInfoList[i-1].id)
                                "
                            >
                                <p>最新公告</p>
                                <div class="productNotice-li-text">
                                    {{ proDetails.notictInfoList[i - 1].title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productNotice h100 productPayMnet" v-else>
                    <img
                        src="~assets/images/indexPaymentBackImg.png"
                        class="indexPaymentBackImg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import {
    getProductsNav,
    getRecommendProduct,
    getProductDetails,
} from "api/product";
export default {
    data() {
        return {
            active: 0,
            productList: [
                {
                    id: "0",
                    name: "综合推荐",
                },
            ],
            productList1: [],
            token: PcCookie.get(Key.tokenKey),
            page: {
                pageNo: 1,
                pageSize: 12,
            },
            reProductList: [],
            proDetails: {},
            hasNews: false,
            hasReNews: false,
            hasContent: false,
            payForm: {
                packageType: 0,
                specs: "500个字符",
                efficientTime: "24小时有效",
                price: 0,
            },
        };
    },
    created() {
        this.fetchProductNav();
        this.fetchRecommendData();
    },
    methods: {
        //进入公告详情
        enterNotice(noticeId){
            let routeData = this.$router.resolve({
                path:'/home-notice-details',
                query:{
                    noticeId
                }
            });
            window.open(routeData.href, "_blank");
        },
        //进入产品购买页
        enterPay() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再下载！",
                    type: "warning",
                });
            } else {
                let productId = null;
                let packageType = this.payForm.packageType;
                if (0 < this.active < 6) {
                    productId = this.productList[this.active].id;
                } else {
                    productId = this.productList1[this.active].id;
                }
                if (this.proDetails.ocr) {
                    this.$router.push({
                        path: "/product-ocr-order",
                        query: {
                            productId,
                            packageType,
                        },
                    });
                } else {
                    this.$router.push({
                        path: "/product-order",
                        query: {
                            productId,
                            packageType,
                        },
                    });
                }
            }
        },
        //进入WPS套餐包活动
        enterWpsActivity() {
            let routeData = this.$router.resolve({
                path:'/activity-wps-welfare',
            });
            window.open(routeData.href, "_blank");
        },
        //切换产品套餐类型
        changePackageType() {
            let index = this.payForm.packageType;
            this.payForm.specs =
                this.proDetails.productPaymentRuleList[index].field1 + "字符";
            this.payForm.efficientTime =
                this.proDetails.productPaymentRuleList[index].efficientTime +
                "天";
            this.payForm.price =
                this.proDetails.productPaymentRuleList[index].price;
        },
        useProduct(item) {
            //检测是否登录，打开对应的web端url
            if (
                item.routerPath == "/app-menkocr" ||
                item.routerPath == "/app-translate" ||
                item.routerPath == "/app-recognition" ||
                item.routerPath == "/app-synthesis"
            ) {
                if (
                    this.token == undefined ||
                    this.token == "" ||
                    this.token == null
                ) {
                    this.$message({
                        message: "未登录，去登录后再使用！",
                        type: "warning",
                    });
                } else {
                    let routeData = this.$router.resolve({
                        path: item.routerPath,
                        query: {
                            productId: item.id,
                        },
                    });
                    window.open(routeData.href, "_blank");
                }
            } else {
                let routeData = this.$router.resolve({
                    path: item.routerPath,
                    query: {
                        productId: item.id,
                    },
                });
                window.open(routeData.href, "_blank");
            }
        },
        enterDetails(item) {
            this.$router.push({
                path: "/product-details",
                query: {
                    productId: item.id,
                },
            });
        },
        fetchRecommendData() {
            getRecommendProduct(this.page.pageNo, this.page.pageSize).then(
                (res) => {
                    if (res.success) {
                        this.reProductList = res.body.data.list;
                        this.hasReNews = true;
                    }
                }
            );
        },
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    res.body.data.forEach((item, index) => {
                        if (index < 5) {
                            this.productList.push(item);
                        } else {
                            this.productList1.push(item);
                        }
                    });
                    this.productList1.push({
                        id: "newOldMk",
                        name: "新旧蒙文转换",
                    });
                    this.hasNews = true;
                }
            });
        },
        fetchDetailsList(id) {
            getProductDetails(id).then((response) => {
                if (response.success) {
                    this.proDetails = response.body.data.productInfo;
                    if (this.proDetails.productPaymentRuleList.length > 0) {
                        //OCR单张识别 --> 实时收费，没有订单购买步骤
                        if (this.proDetails.ocr) {
                            this.payForm.packageType = 1;
                            this.payForm.specs =
                                response.body.data.productInfo
                                    .productPaymentRuleList[1].field1 + "字符";
                            this.payForm.price =
                                response.body.data.productInfo.productPaymentRuleList[1].price;
                        } else {
                            this.payForm.packageType = 0;
                            this.payForm.specs =
                                response.body.data.productInfo
                                    .productPaymentRuleList[0].field1 + "字符";
                            this.payForm.price =
                                response.body.data.productInfo.productPaymentRuleList[0].price;
                        }
                        this.payForm.efficientTime =
                            response.body.data.productInfo
                                .productPaymentRuleList[0].efficientTime + "天";
                    }
                    this.hasContent = true;
                }
            });
        },
        changeHotProduct(index, procut) {
            this.active = index;
            if (index != 0) {
                this.fetchDetailsList(procut.id);
                if (index == 11) {
                    this.$router.push("/app-convert-mongolia");
                }
            }
        },
        itemHover(event) {
            event.target.classList.remove("enter");
            event.target.classList.add("leave");
        },
        itemleave(event) {
            event.target.classList.remove("leave");
            event.target.classList.add("enter");
        },
    },
};
</script>

<style>
.productPaymentNews .el-form-item__label {
    font-size: 12px;
}
.productPaymentNews .el-form-item--small {
    margin: 0;
}
.productPaymentNews .el-input--small .el-input__inner {
    font-size: 14px;
    font-weight: bold;
    color: #db0113;
    height: 32px;
    line-height: 32px;
    background: -webkit-linear-gradient(left, #fb6116, #ec3515, #db0113);
    background: -ms-linear-gradient(left, #fb6116, #ec3515, #db0113);
    background: linear-gradient(to right, #fb6116, #ec3515, #db0113);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
</style>
<style  lang="less" scoped>
.payBtn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    z-index: 3;
    background: #f95c16;
    background: -webkit-linear-gradient(to right, #f95c16, #db0013, #f95c16);
    background: -o-linear-gradient(to right, #f95c16, #db0013, #f95c16);
    background: -moz-linear-gradient(to right, #f95c16, #db0013, #f95c16);
    background: linear-gradient(to right, #f95c16, #db0013, #f95c16);
}
.price {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #6f7583;
    p {
        margin-left: 6px;
        color: #db0013;
        em {
            font-size: 24px;
            font-weight: bold;
        }
    }
}
.payAdvantage {
    margin: 12px 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    p {
        width: 66px;
        height: 20px;
        border: solid 1px #dcdcdc;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #868b96;
    }
    p:first-child {
        color: #fff;
        border-color: #db0013;
        background: #db0013;
    }
}
.activityBox {
    img {
        width: 100%;
        height: 60px;
        border: solid 1px #dcdcdc;
        cursor: pointer;
    }
}
.productPayment {
    position: relative;
    .indexPaymentBackImg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
    }
    .productPaymentNews {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        padding: 2px 12px 0;
        > h4 {
            font-size: 18px;
            line-height: 30px;
            color: #252b3a;
        }
        > h6 {
            font-size: 12px;
            color: #868b96;
            margin-bottom: 12px;
        }
    }
}
.productNotice-list {
    margin-top: 42px;
    height: 280px;
    .productNotice-li {
        height: 56px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;
        > p {
            width: 72px;
            padding: 6px 0;
            height: 24px;
            text-align: center;
            border: solid 1px #ff6e16;
            font-size: 12px;
            color: #fd7f32;
        }
        .productNotice-li-text {
            margin-top: 4px;
            width: 154px;
            height: 48px;
            font-size: 12px;
            line-height: 16px;
            color: #777d8a;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }
}
.productNotice {
    width: 242px;
    > p {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
    }
}
.productContent-text {
    height: 66px;
    margin: 7px 0;
    overflow: hidden;
    p {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            margin-right: 8px;
        }
    }
    > span {
        font-size: 14px;
        line-height: 20px;
        height: 42px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: #777d8a;
    }
}
.productContent {
    width: 242px;
    display: flex;
    flex-direction: column;
    color: #252b3a;
    > p {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
    }
}
.menkProduct {
    width: 326px;
    background: #fff;
    box-shadow: 0px 5px 13px 4px #dae0ec;
    position: relative;
    .menkImg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
    }
    .menk-inContent {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 0;
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #252b3a;
        .title {
            font-size: 18px;
            font-weight: bold;
        }
        .menk-inText {
            margin: 12px 0;
            height: 200px;
            font-size: 14px;
            line-height: 28px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
        }
        .menk-btn {
            height: 30px;
            p {
                float: left;
                height: 28px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                width: 114px;
                border: 1px solid #000;
            }
            .useProduct {
                border-color: #1b4094;
                background: #1b4094;
                color: #fff;
            }
            .file {
                margin-left: 8px;
                border-color: #e6e6e6;
                background: #fff;
                color: #252b3a;
            }
        }
    }
}
.menkOcr.active {
    display: flex;
}
.menkOcr {
    display: none;
    flex-direction: row;
    justify-content: space-between;
}
.product-li-tag {
    height: 24px;
    display: flex;
    flex-direction: row;
    p {
        padding: 0 12px;
        height: 22px;
        line-height: 22px;
        border: solid 1px #aaaeb7;
        font-size: 12px;
        color: #808592;
        margin-right: 8px;
    }
}
.product-li-text {
    font-size: 14px;
    line-height: 20px;
    color: #252b3a;
}
.product-li-title {
    font-size: 18px;
    color: #252b3a;
    font-weight: bold;
}
.product-li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 260px;
    height: 97px;
    margin-bottom: 22px;
}
.producList {
    width: 820px;
    display: flex;
    flex-wrap: wrap;
    justify-content: inherit;
    overflow: hidden;
}
.hotProductBtnBox {
    height: 28px;
    p {
        float: left;
        height: 28px;
        border-radius: 4px;
        line-height: 28px;
        font-size: 14px;
        width: 114px;
        text-align: center;
    }
    .useProduct {
        color: #fff;
        background: #1b4094;
    }
    .productDetails {
        margin-left: 8px;
        color: #252b3a;
        background: #fff;
        box-shadow: 0px 3px 3px 0px #d8deeb;
    }
}
.productIntroduce {
    margin: 10px 0;
    font-size: 12px;
    line-height: 18px;
    height: 36px;
    color: #6f7583;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.product-name {
    height: 20px;
    align-items: center;
    display: flex;
    flex-direction: row;
    p {
        font-size: 16px;
        font-weight: bold;
        color: #252b3a;
    }
    span {
        margin-left: 12px;
        padding: 2px 4px;
        font-size: 12px;
        color: #ff8610;
        border: solid 1px #ff8610;
    }
}
.hotProductBox {
    position: absolute;
    left: 16px;
    top: 0;
    z-index: 9;
    width: 296px;
    height: 156px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hotProducBackImg {
    position: absolute;
    right: -30px;
    z-index: 1;
}
.hotProduct {
    padding: 0 16px;
    width: 380px;
    margin: 0 10px;
    height: 156px;
    background: #fff;
    position: relative;
    border-radius: 4px;
    box-shadow: 0 3px 10px 0 #dae0ed;
    overflow: hidden;
}
.hotProduct-container {
    display: flex;
    flex-wrap: wrap;
}
.product-recommend {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.hotProduct-content-container {
    box-sizing: content-box;
    height: 334px;
    padding: 44px 0;
    background: #f3f5f8;
    box-shadow: inset 8px 8px 20px 0 rgba(55, 99, 170, 0.11),
        inset -8px -8px 20px 0 #fff;
}
.product-type-text span.active {
    background: #1b4094;
}

.product-type-text span {
    height: 6px;
    background: #fff;
}
.product-type-text p.active {
    color: #0052d9;
}
.product-type-text p {
    font-size: 15px;
    color: #252b3a;
    text-align: center;
}

.product-type-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 38px;
    margin-top: 8px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    word-break: keep-all;
}
.hotProductImg {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.product-type-container:hover {
    .product-type-text p {
        color: #0052d9;
    }
}
.product-type-container {
    display: flex;
    flex-direction: column;
    height: 105px;
    width: 200px;
    position: relative;
}
.product-tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 20px;
}
.product-modularTitle p {
    font-size: 16px;
    color: #252b3a;
}
.product-modularTitle span {
    font-size: 35px;
    // margin-bottom: 18px;
    color: #252b3a;
    font-weight: 500;
}
.moduular-mcName-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    h4 {
        margin: 0 12px 0 0;
        color: #252b3a;
        font-size: 18px !important;
    }
}
.product-modularTitle {
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
}
.product-modularTitle-tabs {
    display: flex;
    flex-direction: column;
}
.product-container {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    background: #fff;
}
</style>
