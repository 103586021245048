<template>
    <div class="w12 fontBox">
        <div class="font-nav">
            <div
                class="font-nav-li"
                v-for="(item, index) in list"
                :key="item.id"
                :class="{ 'active': activeIndex == index }"
                @click="changeNav(item,index)"
            >
                <img src="~assets/images/font/new.gif" alt="" v-if="index==0">
                <span></span>
                <p>{{ item.name }}</p>
            </div>
        </div>
        <div class="list-news-box" v-if="trueTypeCode == 'MKLZTK_0004'">
            <div class="font-mktitle-box">
                <h4>蒙科立编码字库（TrueType字库）</h4>
                <div>
                    <p>系统关联字库:</p>
                    <span>
                        包括传统蒙古文，托忒文，满文，锡伯文，回鹘式蒙古文，蒙托满阿礼嘎礼字母，界面字体，八思巴文，索永布文，横
                        体方块字。
                        文件名为menksoft.tte，安装在C:\Windows\System32目录下，并设置为注册表[HKEY_CURRENT_USER\EUDC\93
                        6]"SystemDefaultEUDCFont"的键值。其作用是在系统中没有安装任何蒙古文类字体字库的情况下，在系统或编辑器
                        中仍然可以正常显示蒙古文。具体使用方法请见蒙科立蒙古文输入法字体关联工具!（下图：系统关联字库样例）
                    </span>
                </div>
            </div>
            <img
                src="~assets/images/product/mkfontImg.png"
                alt=""
                class="mkfontImg"
            />
            <div class="groupBox">
                <p class="label">关联工具：</p>
                <div>
                    <div class="dowload">
                        <p>SelectFont.exe</p>
                        <!-- <span @click="downloadTrueType">点击下载</span> -->
                    </div>
                </div>
            </div>
            <div class="groupBox">
                <p class="label">使用说明：</p>
                <div>
                    <div class="explainBox">
                        <div>
                            <span>1、</span>
                            <p>
                                如果使用蒙科立蒙古文输入法请直接 选 择“关
                                联蒙科立蒙古文输入法字体”单选框，确定即可。
                            </p>
                        </div>
                        <div>
                            <span>2、</span>
                            <p>
                                如果想使用与蒙科立蒙古文输入法互斥的
                                其它输入法，请选择“关联其它输入法字体”单选框，从下拉式列表中选择对
                                应的TTE文件，若找不到您想使用的字体关联文件，请点击“浏览”指定文件路径，确定即可。
                            </p>
                        </div>
                        <div>
                            <img
                                src="~assets/images/product/mkfontAzImg.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list-news-box" v-else>
            <div class="font-title-box">
                <h4>{{details.name}}</h4>
                <p v-if="details.field1">{{details.field1}}</p>
                <div v-if="activeIndex == 1">
                    <span @click="download(details)">点击下载</span>
                    <em @click="enterDetails(details.code)" v-if="details.code == 'MKLZTK_0002' || details.code == 'MKLZTK_0003'">安装指南>></em>
                    <em @click="enterDetails(details.code)" v-if="details.code == 'MKLZTK_0011'">字体使用方法>></em>
                </div>
            </div>
            <div class="font-mktitle-box" v-if="details.code == 'MKLZTK_0001'">
                <div>
                    <p>字体介绍:</p>
                    <span>
                        精密字体：白体、黑体、哈旺体、报体、阿体、标体、以及新字体芒来体。
                        蒙古文字富有力量感、显示清晰、棱角柔化形成被融化之感、字形结构精致、端庄、
                        横竖笔画粗细比例适度、竖笔粗壮有力布白均匀、行文贯通，既实用又富有装饰效果
                        且清晰易读。适合标题和屏显用字、可用于印刷出版、广告设计、网页设计、屏幕显示等......
                    </span>
                </div>
            </div>
            <div class="font-pri-list">
                <div class="font-pri-li pointer" v-for="( item, index) in showImgList(details.imageUrl)" :key="index" @click="showBigImg(item,index)">
                    <div class="imgBox">
                        <img
                            :src="item"
                            alt=""
                        />
                    </div>
                    <p>{{showImgName(details.imageUrl)[index]}}</p>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="visible"
            :title="showTitle"
            :before-close="handleClose"
            class="showImgBox"
        >
            <img :src="showImg" alt="" class="fntshowImg">
        </el-dialog>
        <div class="agreementBox" v-if="isShow">
            <div class="title">蒙科立字库许可协议</div>
            <div class="agreementText">
                <p style="margin-bottom:20px">协议版本：1.00发布时间：2012年12月01日</p>
                <p>
                    本许可协议适用于内蒙古蒙科立科技股份有限公司（本文简称为"蒙科立公司"）
                    通过蒙科立公司官方网站面向任何个人和机构（本文简称为"客户"）发布的字库软件产品。
                </p>
                <p>
                    本站字库软件的组织结构、命名体系、数据以及与字库软件相关的所有信息均为蒙科立公司版权所有。
                    字库软件及其所包含的字体（即美术作品）受《中华人民共和国著作权法》、《计算机软件保护条例》
                    和其他知识产权法律法规及国际公约、条约的保护。
                </p>
                <p>
                    本许可协议将本字库软件的使用权授予客户，客户仅可应用于非商业用途，并保证不损害蒙科立公司的商业利益，不与蒙科立公司形成竞争。
                </p>
                <p>
                    蒙科立公司将客户从蒙科立公司网站获取并应用字库软件的全部或部分内容视为该客户接受本许可协议。
                </p>
                <p>
                    当蒙科立公司发布许可协议的新版本时，所有字库软件须执行新版本许可协议，包括以往获得并执行以往版
                    本的许可协议的字库软件，但不影响已发生的应用行为及其结果。
                </p>
            </div>
            <div class="btnBox">
                <el-button type="primary" size="small" @click="isAgree = true, isShow = false">同意</el-button>
                <el-button size="small" @click="isAgree = false, isShow = false">不同意</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { updateDownloadNum } from "api/product";
export default {
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        productId:{
            type:String,
            default:''
        }
    },
    computed:{
        showImgList() {
            return function(imgUrl) {
                let imgList = imgUrl.split('|')
                return imgList
            }
        },
        showImgName() {
            return function(imgUrl) {
                let imgList = imgUrl.split('|')
                let imgNameList = []
                imgList.forEach(item => {
                    imgNameList.push(item.substring(item.lastIndexOf('/')+1, item.length - 4))
                })
                return imgNameList
            }
        }
    },
    data() {
        return {
            activeIndex: 0,
            details:{},
            trueTypeCode:'',
            trueLoadPath:'',
            visible:false,
            showImg:'',
            showTitle:'',
            num:0,
            token: PcCookie.get(Key.tokenKey),
            isShow:false,
            isAgree:false
        };
    },
    created () {
        this.details = this.list[0]
    },
    methods:{
        showBigImg(item,index) {
            //精密字体跳转
            if(this.activeIndex == 0){
                let routeData = this.$router.resolve({
                    path:'/font-show',
                    query:{
                        index
                    }
                });
                window.open(routeData.href, "_blank");
            }else{
                this.showImg = item
                this.showTitle = this.showImgName(this.details.imageUrl)[index]
                this.visible = true
            }
        },
        download(item) {
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再下载！",
            //         type: "warning",
            //     });
            // } else {
            if(this.isAgree){
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.style.height = 0;
                iframe.src = item.loadPath;
                document.body.appendChild(iframe);
                setTimeout(() => {
                    iframe.remove();
                }, 5 * 60 * 1000);
                updateDownloadNum(this.productId).then(
                    (res) => {
                        if (res.success) {
                            this.num += 1;
                        }
                    }
                );
            }else{
                this.isShow = true
            }
            //}

        },
        //安装指南、使用手册
        enterDetails(code) {
            let path = ''
            if(code == 'MKLZTK_0002') {
                path = '/download-open-type'
            }else if(code == 'MKLZTK_0003') {
                path = '/download-aat-type'
            }else if(code == 'MKLZTK_0011') {
                path = '/download-photoshop-type'
            }
            let routeData = this.$router.resolve({
                path: path
            });
            window.open(routeData.href, "_blank");
        },
        changeNav(item,index) {
            if(item.code == 'MKLZTK_0004'){
                this.trueLoadPath = item.loadPath
            }
            this.trueTypeCode = item.code
            this.activeIndex = index
            this.details = item
        },
        downloadTrueType() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再下载！",
                    type: "warning",
                });
            } else {
                if(this.isAgree){
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.style.height = 0;
                    iframe.src = this.trueLoadPath;
                    document.body.appendChild(iframe);
                    setTimeout(() => {
                        iframe.remove();
                    }, 5 * 60 * 1000);
                }else{
                    this.isShow = true
                }

            }
        },
        handleClose() {
            this.visible = false;
        },
    }
};
</script>
<style>
.showImgBox .el-dialog__body{
    padding: 0 20px 30px;
}
</style>
<style lang="less" scoped>
.agreementBox{
    width: 736px;
    height: 466px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -233px 0 0 -368px;
    border: solid 8px #c4c9cb;
    background: #f5fbfe;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title{
        padding: 10px 10px 8px;
        height: 38px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        color: #0269b6;
    }
    .agreementText{
        flex: 1;
        padding: 10px;
        overflow-y: auto;
        font-size: 14px;
        color: #555;
        >p{
            text-indent: 28px;
            line-height: 22px;
        }
    }
    .btnBox{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 44px;
        background: #f2f2f2;
    }
}
</style>
<style lang="less" scoped>
.fntshowImg{
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.groupBox {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #252b3a;
    .label {
        font-weight: bold;
    }
    > div {
        min-height: 30px;
        flex: 1;
        margin-left: 12px;
        .explainBox {
            display: flex;
            flex-direction: column;
            div {
                display: flex;
                flex-flow: row;
                font-size: 14px;
                color: #252b3a;
                margin-bottom: 8px;
                p {
                    margin-left: 4px;
                    flex: 1;
                    line-height: 22px;
                }
            }
        }
        .dowload {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: bold;
            span {
                font-size: 12px;
                cursor: pointer;
                margin-left: 6px;
                color: #1b4094;
                text-decoration: underline;
            }
        }
    }
}
.mkfontImg {
    margin: 10px auto 20px;
}
.font-mktitle-box {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "思源黑体";
    h4 {
        font-size: 20px;
        color: #252b3a;
        margin-bottom: 16px;
    }
    div {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: #252b3a;
        line-height: 22px;
        p {
            font-weight: bold;
        }
        span {
            margin-left: 12px;
            flex: 1;
        }
    }
}
.imgBox {
    height: 230px;
    background: #fff;
        box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
    img {
        width: 100%;
        height: 100%;
    }
}
.font-pri-li {
    padding: 4px 0;
    float: left;
    margin: 0 10px 14px;
    width: 282px;
    height: 276px;
    > p {
        line-height: 40px;
        font-size: 14px;
        color: #000;
        font-family: "微软雅黑";
    }
}
.font-pri-list {
    margin-top: 32px;
    overflow: hidden;
}
.font-title-box {
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "思源黑体";
    h4 {
        font-size: 20px;
        color: #252b3a;
        margin-bottom: 16px;
    }
    p {
        font-size: 14px;
        color: #252b3a;
        margin-bottom: 16px;
    }
    div {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
            width: 134px;
            height: 32px;
            border-radius: 6px;
            color: #fff;
            background: #1b4094;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            cursor: pointer;
        }
        em {
            font-style: normal;
            color: #35519b;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.fontBox {
    margin-top: 98px;
    padding-bottom: 20px;
    overflow: hidden;
    .font-nav {
        float: left;
        width: 272px;
        padding: 0 10px;
        border-right: solid 1px #dadada;
        .font-nav-li {
            cursor: pointer;
            padding: 0 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 65px;
            font-style: 14px;
            color: #252b3a;
            border-bottom: solid 1px #d2d2d2;
            position: relative;
            span {
                margin-right: 14px;
                width: 8px;
                height: 8px;
                background: #dfe9ff;
                border-radius: 50%;
            }
            img{
                position: absolute;
                right: 154px;
                top: 8px;
            }
        }
        .font-nav-li.active {
            color: #1b4094;
            background: -webkit-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -o-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: -moz-linear-gradient(to right, #f3f5f8, #cfdcfa);
            background: linear-gradient(to right, #f3f5f8, #cfdcfa);
            span {
                background: #1b4094;
            }
        }
    }
    .list-news-box {
        margin-left: 292px;
    }
}
</style>
