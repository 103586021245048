<template>
    <div class="product-banner">
        <img :src="banner" alt="" class="nannerImg" />
        <div class="w12 h100">
            <div class="banner-title">
                <div class="t-text-box" v-if="wps">
                    <p>此版本仅限单位用户</p>
                    <i class="el-icon-caret-bottom"></i>
                </div>
                <div class="nameBox">
                    <span
                        class="menkTitle"
                        v-html="unEscapeHTML(mongoName)"
                    ></span>
                    <p>{{ name }}</p>
                </div>
                <span>{{ resume }}</span>
                <div class="banner-link-box">
                    <p
                        class="pointer"
                        @click="payProduct"
                        v-if="isPurchase == '1' && !wps"
                    >
                        立即购买
                    </p>
                    <span
                        class="pointer"
                        @click="userNow"
                        v-if="routerPath && !wps"
                    >
                        开始使用
                    </span>
                    <!-- <p
                        class="pointer"
                        v-if="wps"
                        @click="cooperation"
                        style="height: 40px"
                    >
                        合作申请
                    </p> -->
                    <span
                        class="pointer"
                        @click="downloadWps(0)"
                        v-if="productDownloadInfoList.length > 0 && wps"
                        >专业版下载</span
                    >
                    <span
                        class="pointer"
                        @click="downloadWps(1)"
                        v-if="productDownloadInfoList.length > 0 && wps"
                        >教育版下载</span
                    >
                    <h4
                        class="pointer"
                        v-if="productDownloadInfoList.length > 0 && !wps"
                        @click="download"
                    >
                        立即下载
                    </h4>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="蒙科立输入法使用说明"
                        placement="top"
                        v-if="srf"
                    >
                        <i class="el-icon-info" @click="enterArchive"></i>
                    </el-tooltip>
                    <!-- <h4 class="pointer" v-if="productDownloadInfoList.length > 0 && wps" @click="downloadWps(0)" style="margin-right:20px;">专业版下载</h4>
                    <h4 class="pointer" v-if="productDownloadInfoList.length > 0 && wps" @click="downloadWps(1)">教育版下载</h4> -->
                    <!-- <el-dropdown
                        @command="downloadWps"
                        v-if="productDownloadInfoList.length > 0 && wps"
                    >
                        <el-button type="primary">
                            下载<i
                                class="el-icon-arrow-down el-icon--right"
                            ></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :key="0" :command="0"
                                >专业版下载</el-dropdown-item
                            >
                            <el-dropdown-item :key="1" :command="1"
                                >教育版下载</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown> -->
                    <img
                        class="pointer"
                        src="~assets/images/product/mkAiImg.png"
                        alt=""
                        v-if="productDownloadInfoList.length > 0 && wps"
                        style="position: absolute; left: 334px; top: -12px"
                        @click="enterWpsPackage"
                    />
                    <!-- <span class="pointer">在线咨询</span> -->
                </div>
            </div>
            <div class="bannerImg">
                <img :src="productImg" alt="" />
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { updateDownloadNum } from "api/product";
import { gainCompanyService } from "api/personal";
export default {
    props: {
        //背景图
        banner: {
            type: String,
            default: "",
        },
        //产品右侧图片
        productImg: {
            type: String,
            default: "",
        },
        //产品名
        name: {
            type: String,
            default: "",
        },
        //简介
        resume: {
            type: String,
            default: "",
        },
        //产品ID
        productId: {
            type: String,
            default: "",
        },
        //是否付费
        isPurchase: {
            type: String,
            default: "",
        },
        //产品应用web路径
        routerPath: {
            type: String,
            default: "",
        },
        //产品蒙文名称
        mongoName: {
            type: String,
            default: "",
        },
        //是否下载
        productDownloadInfoList: {
            type: Array,
            default() {
                return [];
            },
        },
        ocr: {
            type: Boolean,
            default: false,
        },
        wps: {
            type: Boolean,
            default: false,
        },
        srf: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            token: PcCookie.get(Key.tokenKey),
            memberType: PcCookie.get(Key.memberTypeKey),
            clickNum: 0,
            hasList: false,
        };
    },
    created() {
        if (this.token != undefined) {
            gainCompanyService(1, 10).then((res) => {
                if (res.errorCode == "2000") {
                    if (res.body.data.page.count != 0) {
                        this.hasList = true;
                    } else {
                        this.hasList = false;
                    }
                }
            });
        }
    },
    methods: {
        //进入WPS加载项套餐包详情
        enterWpsPackage() {
            let routeData = this.$router.resolve({
                path: "/activity-wps-welfare",
            });
            window.open(routeData.href, "_blank");
        },
        enterArchive() {
            let routeData = this.$router.resolve({
                path: "/archive",
                query: {
                    fromPath: "产品中心",
                },
            });
            window.open(routeData.href, "_blank");
        },
        cooperation() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再进行操作！",
                    type: "warning",
                });
            } else {
                //如果是个人账户 —— 显示账户升级
                //如果是企业用户 —— 显示合作申请
                if (this.memberType == "0") {
                    this.$message({
                        message: "此功能仅限于单位用户！",
                        type: "warning",
                    });
                } else {
                    this.$emit("cooperation");
                }
            }
        },
        downloadWps(index) {
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再下载！",
            //         type: "warning",
            //     });
            // } else {
            //     if (this.memberType == "0") {
            //         this.$message({
            //             message: "此功能仅限于单位用户！",
            //             type: "warning",
            //         });
            //     } else {
            //         if (this.hasList) {
            updateDownloadNum(this.productId).then((res) => {
                if (res.success) {
                    this.$message({
                        message: "该产品下载成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "该产品下载失败！",
                        type: "warning",
                    });
                }
            });
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = this.productDownloadInfoList[index].loadPath;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
            //         } else {
            //             this.$message({
            //                 message: "请先下合作申请单后才能下载！",
            //                 type: "warning",
            //             });
            //         }
            //     }
            // }
        },
        download() {
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再下载！",
            //         type: "warning",
            //     });
            // } else {
            updateDownloadNum(this.productId).then((res) => {
                if (res.success) {
                    this.$message({
                        message: "该产品下载成功",
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: "该产品下载失败！",
                        type: "warning",
                    });
                }
            });
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = this.productDownloadInfoList[0].loadPath;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
            // }
        },
        payProduct() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再购买！",
                    type: "warning",
                });
            } else {
                this.$router.push({
                    path: this.ocr ? "/product-ocr-order" : "/product-order",
                    query: {
                        productId: this.productId,
                    },
                });
            }
        },
        userNow() {
            if (this.routerPath == "") {
                this.$message({
                    message: "该功能暂未开通，敬请期待...",
                    type: "warning",
                });
            } else if (
                this.routerPath == "/app-menkocr" ||
                this.routerPath == "/app-translate" ||
                this.routerPath == "/app-recognition" ||
                this.routerPath == "/app-synthesis"
            ) {
                if (
                    this.token == undefined ||
                    this.token == "" ||
                    this.token == null
                ) {
                    this.$message({
                        message: "未登录，去登录后再使用！",
                        type: "warning",
                    });
                } else {
                    let routeData = this.$router.resolve({
                        path: this.routerPath,
                        query: {
                            productId: this.productId,
                        },
                    });
                    window.open(routeData.href, "_blank");
                }
            } else {
                let routeData = this.$router.resolve({
                    path: this.routerPath,
                    query: {
                        productId: this.productId,
                    },
                });
                window.open(routeData.href, "_blank");
            }
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再使用！",
            //         type: "warning",
            //     });
            // } else {
            //     if (this.routerPath == "") {
            //         this.$message({
            //             message: "该功能暂未开通，敬请期待...",
            //             type: "warning",
            //         });
            //     } else {
            //         let routeData = this.$router.resolve({
            //             path: this.routerPath,
            //             query: {
            //                 productId: this.productId,
            //             },
            //         });
            //         window.open(routeData.href, "_blank");
            //     }
            // }
        },
        unEscapeHTML(html) {
            html = "" + html;
            return html
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&amp;/g, "&")
                .replace(/&quot;/g, '"')
                .replace(/&apos;/g, "'");
        },
    },
};
</script>
<style lang="less" scoped>
.el-icon-info {
    margin: 4px 0 0 12px;
    font-size: 28px;
    color: #1b4094;
    cursor: pointer;
}
.t-text-box {
    text-align: center;
    font-size: 14px;
    color: #fff;
    height: 24px;
    position: relative;
    p {
        position: absolute;
        right: 10px;
        width: 156px;
        line-height: 24px;
        border-radius: 12px;
        padding: 0 12px;
        background: #ff8811;
    }
    .el-icon-caret-bottom {
        position: absolute;
        right: 28px;
        top: 16px;
        font-size: 18px;
        color: #ff8811;
    }
}
.product-banner {
    height: 378px;
    background: #dae4f5;
    position: relative;
    .nannerImg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
    }
    > .w12 {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 4;
        margin-left: -600px;
        padding: 0 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .banner-title {
            max-width: 580px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #252b3a;
            .nameBox {
                display: flex;
                flex-direction: row;
                margin-bottom: 12px;
                > p {
                    font-size: 45px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                > span {
                    font-size: 30px !important;
                    font-weight: bold;
                    word-break: keep-all;
                    margin-right: 16px;
                }
            }
            span {
                font-size: 14px;
                line-height: 28px;
            }
            .banner-link-box {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                position: relative;
                > p {
                    padding: 0 32px;
                    height: 36px;
                    border: solid 1px #1b4094;
                    background: #1b4094;
                    color: #fff;
                    font-size: 16px;
                    line-height: 36px;
                    margin-right: 20px;
                }
                > span {
                    padding: 0 32px;
                    height: 36px;
                    border: solid 1px #1b4094;
                    background: transparent;
                    color: #1b4094;
                    font-size: 16px;
                    line-height: 36px;
                    margin-right: 20px;
                }
                > h4 {
                    padding: 0 32px;
                    height: 36px;
                    border: solid 1px #1b4094;
                    background: transparent;
                    color: #1b4094;
                    font-size: 16px;
                    line-height: 36px;
                    font-weight: normal;
                }
            }
        }
        .bannerImg {
            display: flex;
            align-items: flex-end;
        }
    }
}
</style>
