<template>
    <div class="main-container" v-if="hasNews">
        <div class="product1 product">
            <div class="w12 h100">
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg1.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle"></p>
                        <div>
                            <el-divider>此版本仅限个人用户</el-divider>
                            <span style="margin-top: 12px"
                                >WPS Office 2019 蒙古文专版</span
                            >
                        </div>
                    </div>
                    <div class="productAdvantage">
                        蒙古文字体完美兼容， 随时随地，办公更高效
                    </div>
                    <div class="introduction">
                        《WPS Office
                        2019蒙古文专版》是由珠海金山办公软件有限公司联合推
                        出，在成熟的《WPS Office
                        2019专业版》上增加了支持蒙古文功能，解
                        决了蒙古文字的编辑排版需求，是一款兼容、开放、高效、安全，并具
                        备民族地区本土化优势的办公软件。
                    </div>
                    <div
                        class="productBtn pointer backBlue"
                        @click="download(0)"
                    >
                        立即下载
                    </div>
                </div>
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg1.png"
                        class="justRight"
                        alt=""
                        style="top: 106px"
                    />
                </div>
            </div>
        </div>
        <div class="product2 product">
            <div class="w12 h100">
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg2.png"
                        class="justLeft"
                        alt=""
                        style="top: 76px"
                    />
                </div>
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg2.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle">
                             <br /> <br />
                        </p>
                        <div>
                            <span>蒙科立智能输入法</span>
                        </div>
                    </div>
                    <div class="productAdvantage">
                        界面简洁,操作方便,功能强大
                    </div>
                    <div class="introduction">
                        基于蒙古文编码国家标准设计实现的蒙科立自主研发产品。输入法词汇量
                        达211多万条，词汇分布在不同功能的词库中，有基本词库、双词词组库、
                        多词词组库和用户自定义词库。键盘布局灵活，符合用户使用习惯。
                    </div>
                    <div class="download-archive">
                        <el-dropdown @command="handleCommand">
                            <span class="el-dropdown-link">
                                请选择<i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="item in productList[1]
                                        .productDownloadInfoList"
                                    :key="item.id"
                                    :command="item"
                                    >{{ item.name }}</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-tooltip class="item" effect="dark" content="蒙科立输入法使用说明" placement="top" >
                            <i class="el-icon-info" @click="enterArchive"></i>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="product1 product">
            <div class="w12 h100">
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg3.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle"> <br /></p>
                        <div>
                            <span>固什汉蒙词典</span>
                        </div>
                    </div>
                    <div class="productAdvantage">词量大，操作简单，易上手</div>
                    <div class="introduction">
                        《固什汉蒙词典》是一部汉蒙数字化词典。研发转化出PC端版本，词
                        典系统操作简便、易学，可满足用户快捷的学习、使用需求。《固什
                        汉蒙词典》曾获2012年国家重点新产品奖。
                    </div>
                    <div
                        class="productBtn pointer backPink"
                        @click="download(2)"
                    >
                        立即下载
                    </div>
                </div>
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg3.png"
                        class="justRight"
                        alt=""
                        style="bottom: 0"
                    />
                </div>
            </div>
        </div>
        <div class="product3 product">
            <div class="w12 h100">
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg5.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle">
                             <br /> <br />
                        </p>
                        <div>
                            <span>蒙科立字体</span>
                        </div>
                    </div>
                    <div class="productAdvantage">
                        多种格式字体，满足不同用户需求。
                    </div>
                    <div class="introduction">
                        基于蒙古文编码国家标准设计并实现的蒙科立字库/字体，属自主研发的发明专利产品，
                        且最先彻底解决了蒙古文印刷技术难以解决的字母黏着连接结构错位与失真的技术难题。
                    </div>
                    <div
                        class="productBtn pointer backBlue2"
                        @click="download('0008')"
                    >
                        立即下载
                    </div>
                </div>
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg5.png"
                        class="justRight"
                        alt=""
                        style="bottom: 0"
                    />
                </div>
            </div>
        </div>
        <div class="product1 product">
            <div class="w12 h100">
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg4.png"
                        class="justLeft"
                        alt=""
                        style="top: 76px"
                    />
                </div>
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg4.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle">
                             <br /> <br /> <br />
                            <br /> <br />
                        </p>
                        <div>
                            <span>蒙科立整词输入法</span>
                        </div>
                    </div>
                    <div class="productAdvantage">
                        界面简洁,操作方便,功能强大
                    </div>
                    <div style="color: red">不建议下载此版本，后期不再维护</div>
                    <div class="introduction">
                        基于蒙古文编码国家标准设计实现的蒙科立自主研发产品。输入法词汇量
                        达211多万条，词汇分布在不同功能的词库中，有基本词库、双词词组库、
                        多词词组库和用户自定义词库。键盘布局灵活，符合用户使用习惯。
                    </div>
                    <div
                        class="productBtn pointer backRed"
                        @click="download(4)"
                    >
                        立即下载
                    </div>
                </div>
            </div>
        </div>
        <div class="product3 product">
            <div class="w12 h100">
                <div class="productImg">
                    <img
                        src="~assets/images/product/productImg6.png"
                        class="justLeft"
                        alt=""
                        style="top: 76px"
                    />
                </div>
                <div class="productNews">
                    <img
                        src="~assets/images/product/productSmallImg6.png"
                        alt=""
                    />
                    <div class="productName">
                        <p class="menkTitle"> <br /> <br />2002</p>
                        <div>
                            <span>蒙古文WPS2002专业版</span>
                        </div>
                    </div>
                    <div class="productAdvantage">
                        多语种的支持、常用文件格式、读写双向兼容
                    </div>
                    <div class="introduction">
                        蒙古文WPS Office
                        2002采用更灵活的定制机制，提供四大主要模块独立运行，降低内存损耗，
                        提高处理速度，让用户更随心所欲的选择要使用的功能；以易用为前提，全面改良界面风格，
                        力求更友好的人机界面。
                    </div>
                    <div
                        class="productBtn pointer backRed2"
                        @click="download(5)"
                    >
                        立即下载
                    </div>
                </div>
            </div>
        </div>
        <College backColor="#fff" />
        <el-dialog
            :visible.sync="visible"
            width="300px"
            :before-close="handleClose"
        >
            <img :src="showImg" alt="" class="showImg" />
        </el-dialog>
        <div class="agreementBox" v-if="isShow">
            <div class="title">蒙科立输入法安装使用协议</div>
            <div class="agreementText">
                <h4>欢迎您使用蒙科立输入法！</h4>
                <p>
                    “蒙科立输入法”是由内蒙古蒙科立科技股份有限公司（以下简称“蒙科立公司”）提供的客户端软件。您在安装“蒙科立输入法”前应仔细阅读本协议，包括免除或者限制蒙科立公司责任的免责条款及对您的权利限制。只要您安装“蒙科立输入法”，即表明您已经完整准确地了解了本协议所有约定，并同意接受本协议的全部条款的约束。
                </p>
                <h4>一. 软件获取、安装、更新</h4>
                <p>
                    1.
                    蒙科立输入法，是一款为用户提供文字输入功能及相关服务的工具类软件产品。您可以通过蒙科立网站或者蒙科立公司授权的第三方网站、平台下载本软件。如果您从未经蒙科立公司授权的第三方网站、平台获取本软件或与本软件名称相同的安装程序，蒙科立公司无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
                </p>
                <p>
                    2.
                    蒙科立公司可能为不同的终端设备开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装，否则无法享受到相应服务，且您不得将本软件安装在未经蒙科立公司明示许可的其他终端设备上。由于您未正确安装合适的软件版本所带来的风险及损失，蒙科立公司不承担任何责任。
                </p>
                <p>
                    3.
                    如果您不再需要使用本软件或者需要安装新版软件，您可以自行卸载本软件。如果您愿意帮助蒙科立公司改进产品服务，请告知卸载的原因。
                </p>
                <p>
                    4.
                    为了增进用户体验、完善服务内容，蒙科立公司将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。
                </p>
                <p>
                    5.
                    为了改善用户体验，并保证服务的安全性和功能的一致性，蒙科立公司有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。
                </p>
                <p>
                    6.
                    本软件新版本发布后，旧版本的软件可能无法使用。蒙科立公司不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
                </p>
                <p>
                    7.
                    如果您安装使用本软件及服务，即表示您同意接受蒙科立公司为您提供上述服务，及为提供上述服务而发出的相关通知及或蒙科立公司系统消息（包括但不限于弹窗形式推送的资讯及广告信息等）。蒙科立会根据相关法律法规的规定提供前述内容的关闭方式，如您认为前述通知或系统消息给您造成打扰，您可以设置关闭相关服务。
                </p>
                <h4>二. 权利声明</h4>
                <p>
                    1.蒙科立公司拥有“蒙科立输入法”的所有权及知识产权等全部权利（“软件”相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等相关的知识产权），受国际版权公约、中华人民共和国著作权法、专利法、及其他知识产权方面的法律法规的保护。
                </p>
                <p>
                    2.蒙科立公司仅授予您对“蒙科立输入法”的可撤销的、有限的、非排他的、不可转让的使用权，您仅限于以非商业使用蒙科立输入法为目的进行使用。
                </p>
                <p>
                    3.您不得修改、改编、翻译“蒙科立输入法”，或者创作“蒙科立输入法”的衍生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得“蒙科立输入法”源代码，否则由此引起的一切法律后果由您负责，蒙科立公司将依法追究违约方的法律责任。
                </p>
                <p>
                    4.您不得修改、复制、传播与“蒙科立输入法”相关的材料。如果您复制和修改传播这些材料，因此而造成对其他人的损害，或者造成对蒙科立公司形象损害，要承担相应的法律责任。您不得删除、掩盖或更改蒙科立公司的版权声明、商标或其它权利声明。
                </p>
                <p>
                    5.对于您上传至蒙科立输入法或蒙科立网站的皮肤、表情等，您同意授权蒙科立公司享有永久的、不可撤销的、全球范围内的、免费的使用权，蒙科立公司可以根据其经营或业务需要进行任何方式及范围的使用。
                </p>
                <p>6.您理解，蒙科立输入法中的内容可能来源于第三方，如您认为蒙科立输入法中存在侵犯您合法权益的内容或信息，您有义务提前通知蒙科立公司，蒙科立公司将在法律规定的范围内，对侵权内容进行及时处理，您同意放弃追究蒙科立公司任何责任。</p>
                <h4>三. 义务和责任限制</h4>
                <p>
                    1.蒙科立公司授权您非商业性使用“蒙科立输入法”软件。这意味着您可以自主选择下载、安装、使用及停止使用或卸载“蒙科立输入法”。但是如果要进行商业性使用（包括但不限于：销售、复制、散发、预装、捆绑或用于其他商业活动）时，必须事先获得蒙科立公司的书面授权和许可。另外，您在使用“蒙科立输入法”时，不得有违反法律、危害网络安全或损害第三方合法权益之行为，否则由此产生的后果均由您自己承担，蒙科立公司对您不承担任何责任，蒙科立公司保留对您因上述行为而对蒙科立公司造成损失而主张赔偿的权利。
                </p>
                <p>
                    2.您不得将蒙科立输入法用于任何违反法律法规、违背公序良俗的活动，否则，蒙科立公司有权立即解除本协议，暂停或停止为您提供服务。如有违法犯罪等行为的，将移交有权机关处理，所有后果及责任均由您自行承担。
                </p>
                <p>
                    3.您可以就“蒙科立输入法”的下载、安装、使用或卸载问题向蒙科立公司提出咨询，蒙科立公司解答的义务不应超过蒙科立公司的合理承受限度。如果您对“蒙科立输入法”有任何意见，或有如何改进的建议，请使用"用户反馈"功能发表您的意见或建议。
                </p>
                <h4>四.无担保声明</h4>
                <p>
                    1.虽然“蒙科立输入法”（包括其升级版）经过蒙科立公司详细的测试，但蒙科立公司不能保证其与所有的软硬件、系统完全兼容。如果出现不兼容的情况您可将情况报告蒙科立公司，以获得技术支持。如果无法解决问题，您可以选择卸载“蒙科立输入法”。
                </p>
                <p>
                    2.由于“蒙科立输入法”可以通过网络途径下载、传播，对于从非蒙科立公司指定官方站点下载的“蒙科立输入法”以及从非蒙科立公司发行的介质上获得的“蒙科立输入法”，公司无法保证该软件是否感染计算机病毒、是否隐藏有伪装的特洛伊木马程序等黑客软件，也不承担对由此使您所遭受的一切直接或间接损害的赔偿等法律责任。
                </p>
                <p>
                    3.您理解并同意自主选择下载和使用“蒙科立输入法”，风险自负，包括但不限于您使用“蒙科立输入法”过程中的行为，以及因使用“蒙科立输入法”而产生的一切后果。如因下载或使用“蒙科立输入法”而对计算机系统造成的损坏或数据的丢失，您须自行承担全部责任。在法律允许的最大限度内，蒙科立公司明确表示不做出任何明示、暗示和强制的担保，包括但不限于适销性、针对特定用途的适用性以及不侵犯所有权的担保。蒙科立公司不做出任何与“蒙科立输入法”的安全性、可靠性、及时性和性能有关的担保。蒙科立公司有权在任何时候，暂时或永久地变更、中断或终止“蒙科立输入法”或其中任何一部分。蒙科立公司对本服务的变更、中断或终止，对您和任何第三人均不承担任何责任。
                </p>
                <h4>五.隐私保护</h4>
                <p>1.个人信息是指以电子或其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息等。</p>
                <p>2.保护用户个人信息是蒙科立的一项基本原则，蒙科立保证在符合国家相关法律法规及蒙科立隐私政策的前提下收集、存储、使用您的个人信息，并承担将通过合理技术手段、强化管理制度等措施保障您个人信息的安全。</p>
                <p>3.蒙科立尊重并尽最大努力保护您的个人信息，但是不能保证现有的安全技术措施能确保您的个人信息不受任何形式的侵犯。</p>
                <p>4.具体个人信息保护相关内容可参见《蒙科立公司隐私政策》，本协议约定与《蒙科立公司隐私政策》条款有不一致之处，适用本协议。《蒙科立公司隐私政策》的相关条款在本协议中未约定的，依照《蒙科立公司隐私政策》约定执行。</p>
                <h4>六.通知和送达</h4>
                <p>
                    1.您在使用“蒙科立输入法”过程中有任何意见或建议，可通过意见反馈渠道提出，蒙科立公司将向您及时做出反馈。您应保证您的联系方式畅通，以接收来自蒙科立公司发出的邮件、电话或信息，蒙科立公司不对您未收到通知而引起的任何损失承担责任。
                </p>
                <p>
                    2.您可以按照蒙科立公司所公布的如下联系方式向蒙科立公司发出通知，蒙科立公司在收到通知之日起视为送达。
                </p>
                <p>
                    地址：呼和浩特市如意开发区低碳总部大厦7层收<br>
                    联系电话：0471-6921944<br>
                    电子邮件地址：office@menksoft.com
                </p>
                <h4>七.关于其他</h4>
                <p>
                    1.蒙科立公司保留随时修改本协议的权利并无须逐一通知，最新版本之协议将公布于蒙科立网站，一经公布即视为已经通知所有用户。您对蒙科立公司修改后的协议如有异议，可选择卸载“蒙科立输入法”，由此给您造成的任何损失蒙科立公司均不承担责任。您继续使用“蒙科立输入法”即表明您完全同意蒙科立公司对本协议的修改。
                </p>
                <p>
                    2.本协议适用中华人民共和国法律。如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可提交法院裁决。
                </p>
            </div>
            <div class="btnBox">
                <el-button
                    type="primary"
                    size="small"
                    @click="(isAgree = true), (isShow = false)"
                    >同意</el-button
                >
                <el-button
                    size="small"
                    @click="(isAgree = false), (isShow = false)"
                    >不同意</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { getDownloadProduct, updateDownloadNum } from "api/product";
import College from "views/routerViews/Download/College";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content:
                    "蒙古文OCR，机器翻译，固什词典，wps蒙古文专版，蒙古文校对，编码转换",
            },
        ],
    },
    components: { College },
    data() {
        return {
            token: PcCookie.get(Key.tokenKey),
            productList: [],
            hasNews: false,
            azFontImg: require("@/assets/images/product/azFontImg.png"),
            iosFontImg: require("@/assets/images/product/iosFontImg.png"),
            showImg: "",
            visible: false,
            isShow: false,
            isAgree: false,
        };
    },
    created() {
        this.getProduct();
    },
    methods: {
        enterArchive(){
            let routeData = this.$router.resolve({
                path: '/archive',
                query: {
                    fromPath: '下载',
                },
            });
            window.open(routeData.href, "_blank");
        },
        handleCommand(command) {
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再下载！",
            //         type: "warning",
            //     });
            // } else {
            if (this.isAgree) {
                // if (command.code == "MKLSRF_0005") {
                //     this.showImg = this.azFontImg;
                //     this.visible = true;
                // } else
                if (command.code == "MKLSRF_0004") {
                    this.showImg = this.iosFontImg;
                    this.visible = true;
                } else {
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.style.height = 0;
                    iframe.src = command.loadPath;
                    document.body.appendChild(iframe);
                    setTimeout(() => {
                        iframe.remove();
                    }, 5 * 60 * 1000);
                }
            } else {
                this.isShow = true;
            }
            //}
        },
        download(index) {
            if (index === "0008") {
                this.$router.push({
                    path: "/download-font",
                    query: {
                        productId: this.productList[3].id,
                    },
                });
            } else {
                // if (
                //     this.token == undefined ||
                //     this.token == "" ||
                //     this.token == null
                // ) {
                //     this.$message({
                //         message: "未登录，去登录后再下载！",
                //         type: "warning",
                //     });
                // } else {
                updateDownloadNum(this.productList[index].id).then(
                    (res) => {
                        if (res.success) {
                            this.productList[index].clickNum += 1;
                        }
                    }
                );
                const iframe = document.createElement("iframe");
                iframe.style.display = "none";
                iframe.style.height = 0;
                iframe.src =
                    this.productList[
                        index
                    ].productDownloadInfoList[0].loadPath;
                document.body.appendChild(iframe);
                setTimeout(() => {
                    iframe.remove();
                }, 5 * 60 * 1000);
                //}
            }
        },
        getProduct() {
            getDownloadProduct().then((res) => {
                if (res.success) {
                    this.productList = res.body.data;
                    this.hasNews = true;
                }
            });
        },
        handleClose() {
            this.visible = false;
        },
    },
};
</script>
<style lang="less" scoped>
.download-archive{
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .el-dropdown{
        margin:0;
    }
}
.el-icon-info{
    margin: 0 0 0 12px;
    font-size: 28px;
    color: #0598f9;
    cursor: pointer;
}
.agreementBox {
    width: 736px;
    height: 466px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -233px 0 0 -368px;
    border: solid 8px #c4c9cb;
    background: #f5fbfe;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
        padding: 10px 10px 8px;
        height: 38px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 700;
        color: #0269b6;
    }
    .agreementText {
        flex: 1;
        padding: 10px;
        overflow-y: auto;
        font-size: 14px;
        color: #555;
        > p {
            text-indent: 28px;
            line-height: 22px;
        }
        > h4 {
            margin: 6px 0;
        }
    }
    .btnBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 44px;
        background: #f2f2f2;
    }
}
</style>
<style lang="less">
.showImg {
    width: 260px;
}
.product .el-dropdown {
    cursor: pointer;
    margin-top: 36px;
    width: 180px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-family: "思源黑体";
    color: #fff;
    background: #0598f9;
}
.productName {
    .el-divider {
        margin: 0;
        background: #ff8710;
    }
    .el-divider__text {
        background: #ff8710;
        border-radius: 12px;
        color: #fff;
        font-size: 12px;
        padding: 3px 20px;
        font-family: "思源黑体";
        white-space: nowrap;
    }
}
</style>
<style lang="less" scoped>
.product1 {
    background: #f4f8ff;
}
.product2 {
    background: #fdfdfd;
}
.product3 {
    background: #fff;
}
.product {
    height: 706px;

    .w12 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .productNews {
            width: 412px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            z-index: 9;
            > img {
                height: 60px;
                margin-bottom: 20px;
            }
            .productName {
                display: flex;
                flex-direction: row;
                align-items: center;
                .menkTitle {
                    font-size: 24px !important;
                    color: #252b3a;
                    margin-right: 10px;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    span {
                        font-size: 26px;
                        font-family: "思源黑体";
                        color: #252b3a;
                    }
                }
            }
            .productAdvantage {
                margin: 23px 0;
                font-size: 18px;
                font-family: "思源黑体";
                color: #595c61;
            }
            .introduction {
                width: 412px;
                font-size: 14px;
                font-family: "思源黑体";
                color: #595c61;
                line-height: 26px;
            }
            .productBtn {
                margin-top: 36px;
                width: 180px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                font-family: "思源黑体";
                color: #fff;
            }
            .backBlue {
                background: #5a86e9;
            }
            .backLightBlue {
                background: #0598f9;
            }
            .backBlue2 {
                background: #2544b0;
            }
            .backPink {
                background: #ff7373;
            }
            .backRed {
                background: #f52525;
            }
            .backRed2 {
                background: #c42c17;
            }
        }
        .productImg {
            flex: 1;
            height: 100%;
            position: relative;
            img {
                position: absolute;
            }
        }
        .justLeft {
            left: 0;
        }
        .justRight {
            right: 0;
        }
    }
}
</style>
