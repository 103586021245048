<template>
    <div
        id="applicationBox"
        v-loading="loading"
        element-loading-text="内容识别中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.6)"
    >
        <div class="codeImgBox" v-if="isPay">
            <div class="payImgBox">
                <p>
                    三分钟倒计时: <em>00:{{ minute }}:{{ second }}</em>
                </p>
                <img :src="payImg" alt="" class="payImg" />
                <span>提示：请在三分钟之内进行支付</span>
            </div>
        </div>
        <div id="entrustNewsBox" v-if="isEntrust">
            <div class="entrustNewsBox">
                <div class="closeBox">
                    <p @click="isEntrust = false">
                        <i class="el-icon-close"></i>
                    </p>
                </div>
                <div class="i-success-box">
                    <i class="el-icon-check"></i>
                </div>
                <div class="applyOk">“申请委托”已通过</div>
                <div class="secret-key-box">
                    <span @click="downloadTxt">*请点击此处下载.TXT文件</span>
                </div>
                <div class="emailNewsBox">
                    <img src="~assets/images/wtEmailImg.png" alt="">
                </div>
                <!-- <div class="emailNewsBox">
                    将下载后的TXT文件，连同您委托识别的ZIP压缩包文件一
                    并发送至指定邮箱: <span>support@menksoft.com</span>
                </div> -->
                <div class="routetPathBox">
                    该项服务结束后，请前往<span
                        class="pointer"
                        @click="enterPersonalOcr"
                        >“个人中心”——“OCR委托”</span
                    >
                    中下载识别结果。
                </div>
            </div>
        </div>
        <div class="w12 h100 contentBox">
            <div class="ocrBox">
                <el-tabs v-model="activeName">
                    <el-tab-pane name="single">
                        <div slot="label" class="ocrUseType">
                            <img
                                src="~assets/images/product/ocrImg1.png"
                                class="typeImg"
                                alt=""
                            />
                            <span>单张图片识别</span>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="batch">
                        <div slot="label" class="ocrUseType">
                            <img
                                src="~assets/images/product/ocrImg2.png"
                                class="typeImg"
                                alt=""
                            />
                            <span>委托批量识别</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <div class="ocrNewsBox">
                    <div class="single-ocr" v-if="activeName == 'single'">
                        <div class="single-ocr-news">
                            <div class="single-upload-img-box">
                                <img :src="singleImg" alt="" />
                            </div>
                            <div class="ocrBar"></div>
                            <div class="ocrResultBox">
                                <div class="title">识别结果</div>
                                <div class="ocrResultTextBox menkTitle">
                                    <div class="menkContent">
                                        <p v-html="singleRsult"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-ocr-btn">
                            <div>
                                <el-upload
                                    class="avatar-uploader"
                                    accept="image/png,image/jpg,image/jpeg"
                                    action="#"
                                    :http-request="uploadImg"
                                    :show-file-list="false"
                                    :before-upload="frontBeforeUpload"
                                >
                                    <p class="changeImg">上传图片</p>
                                </el-upload>
                            </div>
                            <div class="ocr-btn">
                                <p
                                    @click="coptyText"
                                    class="poniter"
                                    :data-clipboard-text="singleRsult"
                                    id="ocr"
                                >
                                    <i class="el-icon-document-copy"></i>
                                    复制全文
                                </p>
                                <div @click="distinguish">开始识别</div>
                            </div>
                        </div>
                    </div>
                    <div class="batch-ocr" v-else>
                        <img src="~assets/images/product/ocrImg3.png" alt="" />
                        <el-button
                            type="success"
                            class="entrustBtn"
                            @click="entrust"
                            :disabled="disabled"
                            >申请委托</el-button
                        >
                        <div class="entrustAttention">
                            <div>
                                <p>服务规格：</p>
                                <span>
                                    图片格式：JPEG、PNG<br />
                                    文件格式：ZIP压缩文件
                                </span>
                            </div>
                            <p></p>
                            <div>
                                <p>服务规格：</p>
                                <span>
                                    用户须严格遵守“服务规格”的
                                    各项要求，并通过<em>“申请委托”</em>
                                    确认委托服务，在线申请记录将
                                    作为我方提供相应服务的依据，
                                    <i>若无此项申请记录则视为无效。</i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="drag-verify-img" v-if="!isPassing">
            <drag-verify-img-chip
                ref="dragVerify"
                :width="350"
                :imgsrc="imgsrc"
                :isPassing.sync="isPassing"
                :showRefresh="true"
                :barWidth="40"
                text="请按住滑块拖动"
                successText="验证通过"
                handlerIcon="el-icon-d-arrow-right"
                successIcon="el-icon-circle-check"
            >
            </drag-verify-img-chip>
        </div>

        <!-- <page-slide
            ref="child"
            :visible="visible"
            :remoteClose="remoteClose"
        ></page-slide> -->
    </div>
</template>
<script>
import dragVerifyImgChip from "vue-drag-verify-img-chip";
import Clipboard from "clipboard";
// import Slide from "@/components/contents/Slide";
import { socrweb, socrlt, entrustOCRList } from "api/product";
import {  queryOrder } from "api/order";
export default {
    // components: {
    //     //引入子组件，子组件命名
    //     "page-slide": Slide,
    // },
    components: {
        dragVerifyImgChip,
    },
    computed:{
        second: function () {
            return this.num(this.seconds);
        },

        minute: function () {
            return this.num(this.minMinutes);
        },
    },
    mounted() {
        document.onkeydown =  function (event) {
            if (event.keyCode === 123) {
                event.preventDefault();
                window.event.returnValue = false;
            }
        };
        document.oncontextmenu = function (event) {
            event.preventDefault();
            window.event.returnValue = false;
        };
    },
    data() {
        return {
            loading: false,
            activeName: "single",
            singleImg: "",
            singleRsult: "",
            isEntrust: false,
            productId: "",
            downloadPath: "",
            disabled: false,
            visible: true,
            isPay:false,
            payImg:'',
            seconds: 0,
            minMinutes: 3,
            timer:null,
            rlt_key:'',
            orderId:'',
            isPassing:false,
            value: "",
            imgsrc: require("@/assets/images/temporary/verifyImg.jpg"),
        };
    },
    created() {
        this.productId = this.$route.query.productId;
    },
    methods: {
        enterPersonalOcr() {
            let routeData = this.$router.resolve({
                path: "/personal/ocr",
                query: {
                    productId: this.productId,
                },
            });
            window.open(routeData.href, "_blank");
        },
        downloadTxt() {
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.style.height = 0;
            iframe.src = this.downloadPath;
            document.body.appendChild(iframe);
            setTimeout(() => {
                iframe.remove();
            }, 5 * 60 * 1000);
        },
        //批量委托
        entrust() {
            entrustOCRList(this.productId).then((res) => {
                if (res.success) {
                    if (res.body.data.isBuy) {
                        this.isEntrust = true;
                        this.downloadPath = res.body.data.url;
                    } else {
                        this.$message({
                            message:
                                "您还未开通此项服务或套餐内服务项目已使用完（或服务已过期）！",
                            type: "warning",
                            duration: 1000,
                        });
                        setTimeout(() => {
                            this.$router.push({
                                path: "/product-ocr-order",
                                query: {
                                    productId: this.productId,
                                },
                            });
                        }, 1500);
                    }
                    this.disabled = true;
                } else {
                    this.$message({
                        message: res.msg,
                        type: "erroe",
                        duration: 1000,
                    });
                    this.isEntrust = false;
                }
            });
        },
        //复制识别结果
        coptyText() {
            this.outerVisible = true;
            let that = this;
            if (this.singleRsult.length == 0) {
                this.$message({
                    message: "请上传图片，进行ocr识别！",
                    type: "warning",
                    duration: 1000,
                });
            } else {
                let clipboard = new Clipboard("#ocr");
                clipboard.on("success", () => {
                    that.$message.success("复制成功！");
                    clipboard.destroy();
                });
                clipboard.on("error", () => {
                    that.$message.success("复制失败！");
                    clipboard.destroy();
                });
            }
        },
        //单张ocr识别
        distinguish() {
            this.loading = true
            socrweb(this.singleImg, this.productId).then((res) => {
                if(res.success){
                    if (res.body.shouldPay) {
                        //需再支付
                        this.isPay = true
                        this.payImg = res.body.data.qrcode
                        this.rlt_key = res.body.rlt_key
                        this.orderId = res.body.data.id
                        this.Countdown(this.orderId)
                    } else {
                        //无需再付款
                        this.isPay = false;
                        this.singleRsult = res.body.data;
                    }

                }else{
                    this.$message({
                        message: res.msg,
                        type: "warning",
                    });
                }
                this.loading = false
            });
        },
        //上传单张图片
        uploadImg(data) {
            this.singleRsult = ''
            let that = this;
            let rd = new FileReader();
            let file = data.file;
            rd.readAsDataURL(file);
            rd.onloadend = function () {
                that.singleImg = this.result;
            };
        },
        frontBeforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message({
                    message: "上传文件不能超过2MB！",
                    type: "warning",
                });
                return false;
            }
        },
        remoteClose() {
            this.visible = false;
        },
        num(n) {
            return n < 10 ? "0" + n : "" + n;
        },
        Countdown(id) {
            var _this = this;
            _this.timer = window.setInterval(function () {
                if (_this.seconds === 0 && _this.minMinutes !== 0) {
                    _this.seconds = 59;
                    _this.minMinutes -= 1;
                } else if (_this.minMinutes === 0 && _this.seconds === 0) {
                    _this.seconds = 0;
                    window.clearInterval(_this.timer);
                    _this.isPay = false;
                    _this.payImg = "";
                } else {
                    _this.seconds -= 1;
                }
                queryOrder(id).then((res) => {
                    if (res.success) {
                        if (res.body.data.status == "3") {
                            window.clearInterval(_this.timer);
                            _this.isPay = false;
                            _this.payImg = "";
                            //识别结果
                            socrlt(_this.rlt_key).then(res => {
                                if(res.success) {
                                    _this.singleRsult = res.body.data
                                }else{
                                    _this.$message({
                                        message: res.msg,
                                        type: "warning",
                                    });
                                }
                            })
                        }
                    }
                });
            }, 1000);
        },
    },
    beforeDestroy() {
        document.onkeydown = ''
        document.oncontextmenu = ''
        if(this.timer) { //如果定时器还在运行 或者直接关闭，不用判断
            clearInterval(this.timer); //关闭
        }
    },
    watch: {
        second: {
            handler(newVal) {
                this.num(newVal);
            },
        },
        minute: {
            handler(newVal) {
                this.num(newVal);
            },
        },
    },
};
</script>
<style>
.ocrBox .el-tabs__item {
    height: 54px;
    line-height: 54px;
}
.ocrBox .el-tabs__header {
    margin: 0;
}
.ocrBox .el-tabs__nav-scroll {
    padding: 0 30px;
}
.ocrBox .el-tabs__active-bar {
    height: 3px;
    background-color: #1b4094;
}
.ocrBox .el-tabs__active-bar {
    right: 0;
    width: 117px !important;
}
</style>
<style lang="less" scoped>
.drag-verify-img{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content:center;
    align-items: center;
}
.codeImgBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.payImgBox {
    width: 370px;
    height: 444px;
    border: solid 1px #252b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    img {
        border: solid 1px #ccc;
        width: 236px;
    }
    p {
        margin: 16px 0;
        font-size: 22px;
        color: #252b3a;
        em {
            color: #ff8610;
            font-style: normal;
        }
    }
    span {
        font-size: 16px;
        color: #252b3a;
        margin: 20px 0;
    }
}
.routetPathBox {
    margin: 0 32px;
    font-size: 14px;
    color: #252b3a;
    line-height: 20px;
}
.emailNewsBox {
    margin: 20px 32px;
    font-size: 14px;
    color: #252b3a;
    line-height: 20px;
    span {
        color: #ff0000;
    }
}
.secret-key-box {
    margin: 0 32px 6px;
    height: 28px;
    border-bottom: solid 1px #ff0000;
    span {
        font-size: 20px;
        font-family: "思源";
        color: #ff0000;
        cursor: pointer;
    }
}
.applyOk {
    margin-bottom: 36px;
    font-size: 18px;
    font-family: "思源";
    font-weight: bold;
    color: #252b3a;
    text-align: center;
}
.i-success-box {
    margin: 20px auto;
    height: 66px;
    width: 66px;
    border-radius: 50%;
    background: #52c41a;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
    }
}
.closeBox {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    height: 22px;
    p {
        margin-right: 10px;
        height: 20px;
        width: 20px;
        line-height: 18px;
        border: solid 1px #7d7d7d;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        i {
            font-style: 14px;
            color: #7d7d7d;
        }
    }
}
.entrustNewsBox {
    width: 414px;
    height: 420px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
#entrustNewsBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
}
.entrustAttention {
    margin-top: 20px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    > div {
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #252b3a;
        > p {
            font-weight: bold;
            margin-bottom: 10px;
        }
        > span {
            line-height: 22px;
            em {
                color: #1bd25c;
                font-style: normal;
            }
            i {
                color: #fe0000;
                font-style: normal;
            }
        }
    }
    > p {
        height: 100%;
        border-left: dashed 1px #ccc;
    }
}
.entrustBtn {
    margin-top: 6px;
    width: 154px;
    background: #1bd25c;
}
.batch-ocr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.ocrResultTextBox {
    padding: 20px;
    height: calc(100% - 70px);
    overflow-x: auto;
    overflow-y: hidden;
    .menkContent {
        height: 100%;
        p {
            word-wrap: break-word;
            word-break: keep-all;
        }
    }
}
.title {
    padding: 0 20px;
    height: 52px;
    line-height: 52px;
    font-size: 18px;
    font-family: "思源";
    color: #252b3a;
    border-bottom: solid 1px #e5e5e5;
}
.ocrResultBox {
    width: 598px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.ocrBar {
    width: 1px;
    height: 100%;
    background: #e5e5e5;
}
.single-upload-img-box {
    width: 598px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}
.avatar-uploader {
    padding: 6px 20px;
    border: solid 1px #ccc;
}
.single-ocr-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 32px 0;
    height: 78px;
    border-top: solid 1px #e5e5e5;
    .ocr-btn {
        display: flex;
        flex-direction: row;
        height: 32px;
        line-height: 32px;
        p {
            font-size: 14px;
            color: #1b4094;
            cursor: pointer;
        }
        div {
            margin-left: 24px;
            width: 150px;
            height: 32px;
            background-color: #1b4094;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            text-align: center;
        }
    }
}
.single-ocr-news {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100% - 70px);
}
.single-ocr {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.ocrNewsBox {
    height: calc(100% - 54px);
}
.ocrUseType {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        font-size: 16px;
        color: #252b3a;
        font-family: "思源";
        padding-left: 5px;
    }
}
.tabsBox {
    height: 54px;
}
.ocrBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 560px;
    border: solid 1px #e5e5e5;
    background: #fff;
    border-radius: 8px;
}
.contentBox {
    height: 100%;
    padding: 60px 0;
}
#applicationBox {
    min-height: 660px;
}
</style>
