var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container",staticStyle:{"background":"#f3f5f8","overflow":"hidden"}},[_c('div',{staticClass:"w12 fontBox"},[_c('div',{staticClass:"font-nav"},[_c('div',{staticClass:"font-nav-li",class:{'active': _vm.activeIndex == 0},on:{"click":function($event){_vm.activeIndex = 0}}},[_c('span'),_c('p',[_vm._v("OS X安装蒙古文字体教程")])]),_c('div',{staticClass:"font-nav-li border0",class:{'active': _vm.activeIndex == 1},on:{"click":function($event){_vm.activeIndex = 1}}},[_c('span'),_c('p',[_vm._v("iOS嵌入蒙古文字体教程")])])]),(_vm.activeIndex == 0)?_c('div',{staticClass:"list-news-box"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.activeIndex == 1)?_c('div',{staticClass:"list-news-box"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-step-title"},[_c('p',[_vm._v("OS X安装蒙古文字体教程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("第一步：使用字体册打开字体文件")]),_c('div',[_c('img',{attrs:{"src":require("assets/images/product/aatImg1.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("点击“安装字体”")]),_c('img',{attrs:{"src":require("assets/images/product/aatImg2.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("安装完成后在字体册->用户中显示刚才安装的字体")]),_c('img',{attrs:{"src":require("assets/images/product/aatImg3.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("第二步：使用字体新建文本编辑器。点击“字体->显示字体”")]),_c('div',[_c('img',{attrs:{"src":require("assets/images/product/aatImg4.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("选字体集里的所有字体，在所有字体里面找刚在安装的蒙古文字体")]),_c('img',{attrs:{"src":require("assets/images/product/aatImg5.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("关闭窗口，设置完成")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-step-title"},[_c('p',[_vm._v("iOS嵌入蒙古文字体教程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("第一步：将字体库文件拖入项目中")]),_c('div',[_c('img',{attrs:{"src":require("assets/images/product/aatImg6.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("第二步：修改Info.plist:添加Fonts provided by application属性,设值为字体体文件名.")]),_c('div',[_c('img',{attrs:{"src":require("assets/images/product/aatImg7.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("第三步：打开布局文件Main.storyboard,拖一个UILabel控件到界面里,修改UILabel的字体属性Font -> Custom, Font Family -> MenkAAT.")]),_c('div',[_c('img',{attrs:{"src":require("assets/images/product/aatImg8.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }