var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasNews)?_c('div',{staticClass:"wpsActivityDetais",style:(_vm.backStyle)},[_c('div',{staticClass:"wpsActivityPayBox w12"},[_c('div',{staticClass:"wpsActivityPay"},[_c('div',{staticClass:"wpsActivityTitle"},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.packageList[0].name))]),_c('span')]),_c('div',{staticClass:"wpsActivityMoney"},[_c('h4',[_vm._v(_vm._s(_vm.packageList[0].price))]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"wpsActivityPayBtn",on:{"click":function($event){return _vm.enterPay(0)}}},[_vm._v("立即选购")])]),_c('div',{staticClass:"wpsActivityPay"},[_c('div',{staticClass:"wpsActivityTitle"},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.packageList[1].name))]),_c('span')]),_c('div',{staticClass:"wpsActivityMoney"},[_c('h4',[_vm._v(_vm._s(_vm.packageList[1].price))]),_vm._m(2)]),_vm._m(3),_c('div',{staticClass:"wpsActivityPayBtn",on:{"click":function($event){return _vm.enterPay(1)}}},[_vm._v("立即选购")])]),_c('div',{staticClass:"wpsActivityPay"},[_c('img',{staticClass:"gftjImg",attrs:{"src":require("assets/images/temporary/gftjImg.png"),"alt":""}}),_c('div',{staticClass:"wpsActivityTitle"},[_c('span'),_c('p',[_vm._v(_vm._s(_vm.packageList[2].name))]),_c('span')]),_c('div',{staticClass:"wpsActivityMoney"},[_c('h4',[_vm._v(_vm._s(_vm.packageList[2].price))]),_vm._m(4)]),_vm._m(5),_c('div',{staticClass:"wpsActivityPayBtn",on:{"click":function($event){return _vm.enterPay(2)}}},[_vm._v("立即选购")])])]),_vm._m(6),_c('div',{staticClass:"wpsActivityRights w12"},[_c('h4',[_vm._v("WPS蒙科立AI助手使用方法")]),_c('img',{staticClass:"pointer",attrs:{"src":require("assets/images/temporary/wpsHandleImg.jpg"),"alt":""},on:{"click":_vm.enterCollege}})])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("元")]),_vm._v(" / 7天 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpsActivityExplain"},[_c('p',[_vm._v("仅限1个账号使用")]),_c('p',[_vm._v("7款蒙科立AI助手，1天2000字符")]),_c('p',[_vm._v("超值体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("元")]),_vm._v(" / 1年 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpsActivityExplain"},[_c('p',[_vm._v("2个账号使用")]),_c('p',[_vm._v("7款蒙科立AI助手，1年500万字符")]),_c('p',[_vm._v("尊享体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("元")]),_vm._v(" / 1年 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpsActivityExplain"},[_c('p',[_vm._v("5个账号使用")]),_c('p',[_vm._v("7款蒙科立AI助手，1年无限量，极致")]),_c('p',[_vm._v("畅享体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wpsActivityRights w12"},[_c('h4',[_vm._v("WPS蒙科立AI助手超值包权益")]),_c('img',{attrs:{"src":require("assets/images/temporary/wpsImg20220506.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }