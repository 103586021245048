<template>
    <div class="banner-container">
        <swiper ref="mySwiper" :options="swiperOption">
            <!-- <swiper-slide>
                <img
                    src="~assets/images/temporary/bs.png"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <img
                        src="~assets/images/temporary/bs1.png"
                        class="wpsActivity"
                        alt=""
                        style="
                            position: absolute;
                            left: 106px;
                            top: 171px;
                        "
                    />
                    <img
                        src="~assets/images/temporary/bs2.png"
                        class="wpsActivity"
                        alt=""
                        style="
                            position: absolute;
                            right: 0;
                            top: 0;
                        "
                    />
                </div>
            </swiper-slide> -->
            <!-- <swiper-slide>
                <img
                    src="~assets/images/temporary/wpsBannerImg.png"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <img
                        src="~assets/images/temporary/wpsBannerSmallImg.png"
                        class="wpsActivity"
                        alt=""
                        style="
                            position: absolute;
                            left: 42px;
                            bottom: 3px;
                            cursor: pointer;
                        "
                    />
                </div>
            </swiper-slide> -->
            <swiper-slide>
                <img
                    src="~assets/images/temporary/fontBanner.jpg"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <div class="textBox" style="width: 510px">
                        <div class="productNameBox">
                            <em
                                style="
                                    font-style: normal;
                                    font-size: 42px;
                                    font-family: '思源黑体';
                                    font-weight: 500;
                                    color: #242a3a;
                                "
                                >精工设计 美感升级</em
                            >
                        </div>
                        <div
                            style="
                                margin-bottom: 6px;
                                height: 36px;
                                line-height: 36px;
                            "
                        >
                            <p
                                style="
                                    font-size: 20px;
                                    font-family: '思源黑体';
                                    color: #242a3a;
                                    text-align: left;
                                "
                            >
                                抓专业品质 创字体品牌 获用户心声
                            </p>
                        </div>
                        <p
                            style="
                                color: #8b8a8a;
                                text-align: left;
                                font-size: 16px;
                                color: #5d6063;
                                line-height: 30px;
                            "
                        >
                            精密字体2022全新升级上线，7款优化过的精密字体为：白体、黑体、哈旺体、
                            报体、阿体、标体，以及一款新字体“芒来体”。蒙古文字具有独特的形态美
                            和意蕴美、鲜明地体现了蒙古民族潇洒、灵动的文化精神。
                        </p>
                        <div style="display: flex; flex-direction: row">
                            <div
                                class="mlFont"
                                style="
                                    margin-top: 36px;
                                    width: 148px;
                                    height: 40px;
                                    line-height: 40px;
                                    color: #252b3a;
                                    text-align: center;
                                    cursor: pointer;
                                    border: solid 1px #252b3a;
                                    font-size: 14px;
                                "
                            >
                                查看详情
                            </div>
                        </div>
                    </div>
                    <img
                        src="~assets/images/temporary/fontBannerSmall.png"
                        alt=""
                    />
                </div>
            </swiper-slide>
            <!-- <swiper-slide>
                <div class="h100 grapBigImg">
                    <img src="~assets/images/grabMonryBannerImg.jpg" alt="" />
                </div>
                <div class="h100 w12 grapSmallImg">
                    <p class="menkTitle"> </p>
                    <img src="~assets/images/grapImg1.png" alt="" class="grapImg" />
                    <img src="~assets/images/grapImg2.png" alt="" style="margin-top: 20px;"/>
                </div>
            </swiper-slide> -->
            <swiper-slide>
                <img
                    src="~assets/images/temporary/wpsStop0021.jpg"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <div class="textBox">
                        <div class="productNameBox">
                            <p class="menkTitle"></p>
                            <span>WPS Office 2019内蒙古专业版</span>
                        </div>
                        <p class="product-jj">
                            蒙古文字体完美兼容，
                            电脑/手机/平板都能用随时随地，办公更高效
                        </p>
                        <div class="enterDetailsBtn">查看详情</div>
                    </div>
                    <img src="~assets/images/officeBannerImg.png" alt="" />
                </div>
            </swiper-slide>
            <swiper-slide>
                <img
                    src="~assets/images/ocrBannerImg1.jpg"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <div class="textBox">
                        <div class="productNameBox">
                            <p class="menkTitle"></p>
                            <span>OCR</span>
                        </div>
                        <p class="product-jj">
                            多场景、高精度的文字检测与识别服务，关键字段识别准确率高达99%
                        </p>
                        <div class="enterDetailsBtn">查看详情</div>
                    </div>
                    <img
                        src="~assets/images/ocrBannerImg.png"
                        alt=""
                        style="position: absolute; right: -121px; top: 16px"
                    />
                </div>
            </swiper-slide>
            <swiper-slide>
                <img
                    src="~assets/images/srfBannerImg1.jpg"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <div class="textBox">
                        <div class="productNameBox">
                            <p class="menkTitle">
                                 <br /> <br />
                            </p>
                            <span>蒙科立输入法</span>
                        </div>
                        <p class="product-jj">
                            蒙科立输入法界面简洁,操作方便,功能强大,为大家带来便捷的使用体验
                        </p>
                        <div class="enterDetailsBtn">查看详情</div>
                    </div>
                    <img src="~assets/images/srfBannerImg.png" alt="" />
                </div>
            </swiper-slide>
            <swiper-slide>
                <img
                    src="~assets/images/jqfyBannerImg1.jpg"
                    class="bannerBackImg"
                    alt=""
                />
                <div class="h100 homeBannerBox">
                    <div class="textBox">
                        <div class="productNameBox">
                            <p class="menkTitle"> <br /></p>
                            <span>机器翻译</span>
                        </div>
                        <p class="product-jj">
                            结合了神经机器翻译和统计机器翻译的优点，从大规模蒙语语料库<br />
                            自动学习翻译知识，实现从源语言文本到目标语言文本的自动翻译，<br />
                            目前可支持蒙汉双语的互译。
                        </p>
                        <div class="enterDetailsBtn">查看详情</div>
                    </div>
                    <img src="~assets/images/jqfyBannerImg.png" alt="" />
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination">
            <em v-for="index in 5" :key="index" @click="slideTo(index)">
                <span :class="{ active: paginationIndex == index }"></span>
            </em>
        </div>
    </div>
</template>
<script>
import { PcCookie, Key } from "@/utils/cookie";
import { getProductsNav } from "api/product";
// import { getActivityStatus } from "@/api/activity"
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        swiper,
        swiperSlide,
    },
    data() {
        let vm = this;
        return {
            realIndex: 0,
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 6000,
                    disableOnInteraction:false,
                },
                on: {
                    click: function (e) {
                        if (e.srcElement.className == "enterDetailsBtn") {
                            vm.realIndex = this.realIndex;
                            vm.enterDetails();
                        }
                        // if( e.srcElement.className == 'grapImg'){
                        //     vm.enterGrabMoney()
                        // }
                        if (e.srcElement.className == "mlFont") {
                            vm.enterFontDownload();
                        }
                        // if (e.srcElement.className == "wpsActivity") {
                        //     vm.enterWpsActivity();
                        // }
                    },
                    slideChange: function () {
                        vm.paginationIndex = this.realIndex + 1;
                    },
                },
            },
            token: PcCookie.get(Key.tokenKey),
            productList: [],
            paginationIndex: 1,
        };
    },
    created() {
        this.fetchProductNav();
    },
    methods: {
        enterFontDownload(){
            this.$router.push({
                path: "/download-font",
                query: {
                    productId: "a0ec7735b5714334934ff3c094ca0a5e",
                },
            });
        },
        // enterWpsActivity() {
        //     this.$router.push("/activity-wps-welfare");
        // },
        // enterWpsNotice() {
        //     this.$router.push({
        //         path: "/home-notice-details",
        //         query: {
        //             noticeId: "0b3c1b5c439149eba10f3ab1f6b9e0db",
        //         },
        //     });
        // },
        // enterGrabMoney(){
        //     if (
        //         this.token == undefined ||
        //         this.token == "" ||
        //         this.token == null
        //     ) {
        //         this.$message({
        //             message: "未登录，去登录后再使用！",
        //             type: "warning",
        //         });
        //     } else {
        //         getActivityStatus('c84622c22906412bb52387d0ad02f426').then(res=> {
        //             if(res.body.isStart){
        //                 this.$router.push("/activity-red-packet");
        //             }else{
        //                 this.$message({
        //                     type:'warning',
        //                     message:'活动未开始，敬请期待...'
        //                 })
        //                 return false
        //             }
        //         })
        //     }
        // },
        slideTo(index) {
            this.paginationIndex = index;
            this.$refs.mySwiper.swiper.slideTo(index, 600, false);
        },
        enterDetails() {
            let idx = null;
            if (this.realIndex == 1) {
                idx = 7;
            } else if (this.realIndex == 2) {
                idx = 0;
            } else if (this.realIndex == 3) {
                idx = 8;
            } else if (this.realIndex == 4) {
                idx = 1;
            }
            this.$router.push({
                path: "/product-details",
                query: {
                    productId: this.productList[idx].id,
                },
            });
        },
        fetchProductNav() {
            getProductsNav().then((res) => {
                if (res.success) {
                    this.productList = res.body.data;
                }
            });
        },
    },
};
</script>
<style>
.swiper-pagination-bullet {
    width: 42px;
    height: 3px;
    border-radius: 0;
}
.swiper-pagination-bullet-active {
    background: #1b4094;
}
</style>
<style scoped lang="less">
.bannerBackImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.grapImg {
    cursor: pointer;
}
.grapSmallImg {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 99;
    margin-left: -600px;
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .menkTitle {
        font-size: 30px !important;
        color: #ffe86f;
    }
}
.grapBigImg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img {
        width: 100%;
        height: 100%;
    }
}
.swiper-pagination {
    position: absolute;
    left: 0;
    bottom: 8px;
    z-index: 999;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    em {
        padding: 16px 0;
        height: 3px;
        cursor: pointer;
    }
    span {
        margin: 0 3px;
        display: inline-block;
        width: 40px;
        height: 3px;
        background: #cacbcc;
    }
    span.active {
        background: #1b4094;
    }
}
.homeBannerBox {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .textBox {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .productNameBox {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            p {
                font-size: 26px !important;
                font-weight: 500 !important;
                color: #252b3a;
                margin-right: 8px;
                text-align: left;
            }
            span {
                font-size: 40px;
                font-family: "思源黑体 CN";
                color: #252b3a;
            }
        }
        .product-jj {
            font-size: 16px;
            color: #595c61;
            line-height: 26px;
            text-align: left;
        }
        .enterDetailsBtn {
            margin-top: 36px;
            width: 148px;
            height: 40px;
            line-height: 40px;
            color: #252b3a;
            text-align: center;
            cursor: pointer;
            border: solid 1px #252b3a;
            font-size: 14px;
        }
    }
}
.swiper-container {
    width: 100%;
    height: 100%;
    /* padding: 90px 0; */
    margin-left: auto;
    margin-right: auto;
    background: #eff1f9;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    height: auto;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition-property: all;
    position: relative;
}
.banner-container {
    height: 560px;
    background: #eff1f9;
    position: relative;
}
</style>
