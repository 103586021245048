var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container",staticStyle:{"background":"#f3f5f8","overflow":"hidden"}},[_c('div',{staticClass:"w12 fontBox"},[_c('div',{staticClass:"font-nav"},[_c('div',{staticClass:"font-nav-li",class:{'active': _vm.activeIndex == 0},on:{"click":function($event){_vm.activeIndex = 0}}},[_c('span'),_c('p',[_vm._v("Windows 字体安装")])]),_c('div',{staticClass:"font-nav-li",class:{'active': _vm.activeIndex == 1},on:{"click":function($event){_vm.activeIndex = 1}}},[_c('span'),_c('p',[_vm._v("Android字体嵌入方法")])]),_c('div',{staticClass:"font-nav-li border0",class:{'active': _vm.activeIndex == 2},on:{"click":function($event){_vm.activeIndex = 2}}},[_c('span'),_c('p',[_vm._v("Linux安装蒙古文字体教程")])])]),(_vm.activeIndex == 0)?_c('div',{staticClass:"list-news-box"},[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.activeIndex == 1)?_c('div',{staticClass:"list-news-box"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.activeIndex == 2)?_c('div',{staticClass:"list-news-box"},[_vm._m(4),_vm._m(5)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-step-title"},[_c('p',[_vm._v("Windows 字体安装指南")]),_c('span',[_vm._v("Windows Vista/7/8/10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("Windows 7 为例：")]),_c('div',[_c('p',[_vm._v("1、双击字体文件，打开字体查看器")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg1.jpg"),"alt":""}})]),_c('div',[_c('p',[_vm._v("2、点击“安装”，安装字体；或，右击字体文件点击“安装”，安装字体")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg2.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-step-title"},[_c('p',[_vm._v("安卓应用开发中自定义字体嵌入方法")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("Typeface使用TTF字体文件设置字体：")]),_c('div',[_c('p',[_vm._v(" 在程序项目中放入ttf字体文件，在程序代码中使用Typeface设置字体。具体步骤如下：a) 在assets目录下新建fonts目录，把ttf字体文件放到这（如下图）。 ")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg3.jpg"),"alt":""}})]),_c('div',[_c('p',[_vm._v(" a) 程序中调用：AssetManager mgr = getAssets(); //得到AssetManager Typeface typeFaceHawang = Typeface.createFromAsset(mgr, \"fonts/MHW8101.ttf\");//根据路径得到Typeface textView.setTypeface(typeFaceHawang); //设置字体textView.setText(\" "),_c('span',{staticClass:"mkText"},[_vm._v("        \\r\\n       \"")]),_vm._v(" );//测试字体 ")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg4.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-step-title"},[_c('p',[_vm._v("Linux安装蒙古文字体教程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exanple"},[_c('p',[_vm._v("Linux Mint 17.2为例：")]),_c('div',[_c('p',[_vm._v(" 第一步：安装字体使用字体查看器打开字体文件。点击“安装”安装字体。 ")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg5.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg6.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg7.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg8.jpg"),"alt":""}})]),_c('div',[_c('p',[_vm._v(" 第二步：使用字体新建空白文档。 ")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg9.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("点击“编辑->首选项”")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg10.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("点击“字体和颜色->编辑器字体”")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg11.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("选择刚才安装的字体，点击确定")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg12.jpg"),"alt":""}}),_c('span',{staticStyle:{"margin-left":"66px"}},[_vm._v("关闭所有窗口，设置完成")]),_c('img',{attrs:{"src":require("assets/images/product/openTypeImg13.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }