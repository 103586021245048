<template>
    <div class="main-container">
        <!-- <div id="newYearGif"  v-if="!isEnd">
            <transition
                appear  enter-active-class="animate__animated animate__zoomIn" leave-active-class="animate__animated animate__zoomOut"
            >
                <img src="~assets/images/temporary/newYear.gif"  alt="">
            </transition>
        </div> -->
        <!-- <transition
            appear  enter-active-class="animate__animated animate__zoomInDown" leave-active-class="animate__animated animate__zoomOut"
        >
            <div id="newYearVideo"  v-if="!isEnd">
                <audio :src="audieoUrl" autoplay></audio>
                <div class="yhBox" style="width:100vw;height:100vh;opacity:0.7">
                    <vue-core-video-player
                        :src="yhUrl"
                        :controls="false"
                        :autoplay="true"
                        :muted="true"
                    ></vue-core-video-player>
                </div>
                <div class="close-i-box">
                    <p @click="isEnd = true">
                        <i class="el-icon-circle-close"></i>
                    </p>
                </div>
                <img src="~assets/images/temporary/BorderImg.png" class="i-BorderImg" alt="">
                <div class="newYearVideo">
                    <vue-core-video-player
                        :src="videoUrl"
                        :controls="false"
                        :autoplay="true"
                        :muted="true"
                        @ended="playEnded"
                    ></vue-core-video-player>
                </div>
            </div>
        </transition>
        <transition
            appear  enter-active-class="animate__animated animate__swing" leave-active-class="animate__animated animate__tada"
        >
            <img src="~assets/images/temporary/tiger.gif" @click="showYearVideo" class="tiger" alt="" v-if="isEnd">
        </transition> -->
        <!-- <transition
            appear   leave-active-class="animate__animated animate__fadeOut"
        >
            <div id="wpsActivity" v-if="isShow">
                <div class="wpsActivity">
                    <i class="el-icon-close" @click="isShow = false;"></i>
                    <img src="~assets/images/product/wpsActivityImg.png" class="wpsActivityImg" alt="">
                    <img src="~assets/images/product/wpsActivityBtnImg.png" class="wpsActivityBtnImg"  @click="enterWpsActivity" alt="" >
                </div>
            </div>
        </transition> -->
        <!-- <transition
            appear  enter-active-class="animate__animated animate__swing" leave-active-class="animate__animated animate__tada"
        > -->
            <!-- <img src="~assets/images/temporary/wpsActivityImg.png" @click="enterWpsActivity" class="wpsActivityFixedImg" alt="" v-if="!isShow"> -->
            <!-- <img src="~assets/images/temporary/wpsActivityImg.png" @click="enterWpsActivity" class="wpsActivityFixedImg" alt="">
        </transition> -->
        <Banner />
        <Product />
        <College />
        <Information />
        <Market />
        <!-- <img
            src="~assets/images/college/proposalimg.png"
            alt=""
            class="proposalimg pointer"
            @click="enterProposal"
            v-if="!isEnd"
        /> -->
        <!-- <img
            src="~assets/images/grapMoneyBtn.png"
            alt=""
            class="grapMoneyBtn pointer"
            @click="enterGrabMoney"
            v-if="!isEnd"
        > -->
    </div>
</template>

<script>
import Banner from "views/routerViews/Home/Index/Banner";
import Product from "views/routerViews/Home/Index/Product";
import College from "views/routerViews/Home/Index/College";
import Information from "views/routerViews/Home/Index/Information";
import Market from "views/routerViews/Home/Index/Market";
import { PcCookie, Key } from "@/utils/cookie";
import { getActivityStatus } from "@/api/activity"
export default {
    name: "Home",
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙科立，蒙科立综合服务，蒙科立输入法，蒙古文AI人工智能",
            },
        ],
    },
    components: { Banner, Product, College, Information, Market },
    data() {
        return {
            token: PcCookie.get(Key.tokenKey),
            audieoUrl: require('@/assets/images/temporary/audieo.mp3'),
            videoUrl: require('@/assets/images/temporary/2.mp4'),
            yhUrl:require('@/assets/images/temporary/yh.mp4'),
            isEnd:false,
            isShow:true
        };
    },
    created(){
        const that = this;
        setTimeout(()=>{
            that.isShow = false;
        },5000)
        setTimeout(()=>{
            that.isEnd = true;
        },3500)
    },
    methods: {
        enterWpsActivity(){
            this.$router.push("/activity-wps-welfare");
        },
        showYearVideo(){
            this.isEnd = false
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        playEnded(){
            this.isEnd = true
        },
        enterProposal() {
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再使用！",
                    type: "warning",
                });
            } else {
                this.$router.push("/college-feedback");
            }
        },
        enterGrabMoney(){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再使用！",
                    type: "warning",
                });
            } else {
                getActivityStatus('c84622c22906412bb52387d0ad02f426').then(res=> {
                    if(res.body.isStart){
                        this.$router.push("/activity-red-packet");
                    }else{
                        this.$message({
                            type:'warning',
                            message:'活动未开始，敬请期待...'
                        })
                        return false
                    }
                })
            }
        }
    },
};
</script>
<style lang="less">
#newYearGif{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999999;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.wpsActivity .el-icon-close{
    position: absolute;
    right: 32%;
    top: 15%;
    z-index: 1001;
    cursor: pointer;
    color: #fff;
    font-size: 32px;
}
#newYearVideo video {
    object-fit: initial;
}
</style>
<style lang="less" scoped>
.wpsActivity{
    position: relative;
    width: 1920/@vw;
    height: 1430/@vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .wpsActivityImg{
        height: 1170/@vh;
    }
    .wpsActivityBtnImg{
        position: absolute;
        left: 51%;
        bottom: 212/@vh;
        margin-left: -124/@vw;
        width: 248/@vw;
        height: 160/@vh;
        cursor: pointer;
        animation: likes 0.3s ease-in infinite;
    }
}
@keyframes likes {
    0%{
        transform: scale(1);
    }
    25%{
        transform: scale(0.9);
    }
    50%{
        transform: scale(0.85);
    }
    75%{
        transform: scale(0.9);
    }
    100%{
        transform: scale(1);
    }
}

#wpsActivity{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0,0,0, .6);
}
.wpsActivityFixedImg{
    position: fixed;
    top: calc(50% - 100px);
    right: 0;
    z-index: 99999;
    cursor: pointer;
    animation: scaleAnimate 2s ease-in infinite;
}
@keyframes scaleAnimate {
    0%{
        transform: translateY(0px);
    }
    25%{
        transform: translateY(15px);
    }
    50%{
        transform: translateY(30px);
    }
    75%{
        transform: translateY(15px);
    }
    100%{
        transform: translateY(0px);
    }
}
@vw:19.2vw;
@vh:14.3vh;
.close-i-box{
    width: 1178px;
    position: fixed;
    left: 50%;
    margin-left: -600px;
    margin-top: -300px;
    top: 50%;
    z-index: 99999999;
    height: 30px;
    p{
        padding-left: 90px;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
    }
}
.i-BorderImg{
    width: 1178px;
    position: fixed;
    left: 50%;
    margin-left: -600px;
    z-index: 9999999;
    height: 622px;
    margin-top: -338px;
    top: 50%;
}
#newYearVideo{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    //background: rgba(0,0,0, .5);
}
.newYearVideo{
    width: 1000px;
    height: 500px;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99999;
    margin: -250px 0 0 -500px;
}

.tiger{
    position: fixed;
    top: 46px;
    right: 0;
    z-index: 99999;
    cursor: pointer;
    height: 250px;
}
.proposalimg {
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 999;
    margin-top: -98px;
}
.grapMoneyBtn{
    position: fixed;
    right: 0;
    top: 50%;
    z-index: 999;
    margin-top: -66px;
}
</style>
