<template>
    <div class="main-container">
        <Banner
            :banner="details.field1"
            :productImg="details.field2"
            :isPurchase="details.isPurchase"
            :name="details.name"
            :mongoName="details.mongoName"
            :productId="productId"
            :resume="details.resume"
            :productDownloadInfoList="details.productDownloadInfoList"
            :routerPath="details.routerPath"
            :ocr="details.ocr"
            :wps="details.wps"
            :srf="details.srf"
            @cooperation="cooperation"
        />
        <PackageModular
            v-if="details.isPurchase == '1' && !details.ocr"
            :productId="productId"
            :packageList="details.productPaymentRuleList"
            :singleBuy="details.singleBuy"
            :name="details.name"
        />
        <PackageModularOcr
            v-if="details.isPurchase == '1' && details.ocr"
            :productId="productId"
            :packageList="details.productPaymentRuleList"
            :name="details.name"
        />
        <div class="empower-box" v-if="details.wps">
            <div class="w12">
                <div class="title">
                    <p class="menkTitle"><br/><br/><br/></p>
                    <span>授权须知</span>
                </div>
                <div class="package-list-box">
                    <div class="package-li-box" >
                        <div class="package-type">
                            <span>授权须知</span>
                        </div>
                        <div class="empower-content">
                            <div>
                                <em>1.</em>
                                <p>WPS Office 2019内蒙古专业版/教育版免费试用期限 <span>30天</span>，以版本发布时间为准；软件正版化推广期间，试用期会不定期延长。</p>
                            </div>
                            <div>
                                <em>2.</em>
                                <p>为保障企事业单位合法权益，期间请购买WPS正版授权，年服务费 <i>420元/人/年</i>。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AdvantageModular :advantage="details.productAdvantageInfoList" />
        <Scene :scene="details.productUseScenariosList" />
        <Question
            :questionList="questionList"
            :name="details.name"
            :productId="productId"
            v-if="questionList.length > 0"
        />
        <!--个人账户升级为企业账户入口-->
        <div id="cooperationBox" v-if="visible">
            <div class="cooperationBox">
                <img src="~assets/images/cooperationBackImg.png" alt="">
                <div class="cooperationContent">
                    <div class="icon-close">
                        <i class="el-icon-close" @click="visible = false"></i>
                    </div>
                    <div class="cooperationTitle">请进行企业认证</div>
                    <div class="cooperationTips">当前个人账户未与任何企业关联，无法进行下单操作</div>
                    <div class="cooperationText">
                        <img src="~assets/images/qyImg1.png" alt="">
                        <p>经过提交资料认证成为新<br>的企业认证用户</p>
                    </div>
                    <div class="cooperationBtn" @click="enterCooperation">前往认证</div>
                </div>
            </div>
        </div>
        <!--企业账户下WPS合作单-->
        <div id="wpsOrderBox" v-if="wpsVisible">
            <div class="wpsOrderBox">
                <div class="wpsOrderTitle" >
                    <p v-if="!isSuccess">WPS蒙古文专版合作申请</p>
                    <p v-if="isSuccess"></p>
                    <i class="el-icon-close" @click="closeWpsOrder"></i>
                </div>
                <p class="h1" v-if="!isSuccess"></p>
                <div class="wpsOrderForm" v-if="!isSuccess">
                    <div class="wpsOrderFormGroup">
                        <p>购买终端数量：</p>
                        <el-input-number v-model="purchaseNum" :min="1" :max="9999" size="mini" :disabled="disabled"></el-input-number>
                        <span>人</span>
                    </div>
                    <div class="wpsOrderFormGroup">
                        <p>安装方式：</p>
                        <div class="wpsOrderTypeBox">
                            <div class="wpsOrderType" @click="choseInstallType('0')" :class="{'active': installType == '0'}">
                                <p>自行安装</p>
                                <img src="~assets/images/wpsOrderTypeImg.png" alt="" v-if="installType == '0'">
                            </div>
                            <div class="wpsOrderType" @click="choseInstallType('1')"  :class="{'active': installType == '1'}">
                                <p>远程指导</p>
                                <img src="~assets/images/wpsOrderTypeImg.png" alt="" v-if="installType == '1'">
                            </div>
                        </div>
                    </div>
                    <div class="wpsOrderFormGroup" style="margin-top:22px;">
                        <el-button
                            :disabled="disabled"
                            @click="handleSubmit"
                        >
                            提交申请
                        </el-button>
                    </div>
                </div>
                <div class="isSuccess" v-if="isSuccess">
                    <i class="el-icon-circle-check"></i>
                    <p>提交成功</p>
                </div>
                <div class="wpsOrderzs">
                    <span>*</span>
                    尊敬的企业用户，申请提交成功后我们的技术人员会在第一时间与您联系，请务必保持电
                    话畅通，感谢您的配合与支持。
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueEvent from "@/VueEvent.js";
import { PcCookie, Key } from "@/utils/cookie";
import { getProductDetails, getQuestions,savRecord } from "api/product";
import Banner from "views/routerViews/Product/Details/Banner";
import PackageModular from "views/routerViews/Product/Details/PackageModular";
import PackageModularOcr from "views/routerViews/Product/Details/PackageModular/ocr";
import AdvantageModular from "views/routerViews/Product/Details/AdvantageModular";
import Scene from "views/routerViews/Product/Details/Scene";
import Question from "views/routerViews/Product/Details/Question";
export default {
    metaInfo: {
        meta: [
            {
                name: "keywords",
                content: "蒙古文OCR，机器翻译，固什词典，wps蒙古文专版，蒙古文校对，编码转换",
            },
        ],
    },
    inject: ["reload"],
    components: { Banner, PackageModular, PackageModularOcr, AdvantageModular, Scene, Question },
    data() {
        return {
            isBuy: true,
            details: {},
            questionList: [],
            page: {
                total: 0,
                current: 1,
                size: 6,
            },
            visible:false,
            wpsVisible:false,
            token: PcCookie.get(Key.tokenKey),
            purchaseNum:1,
            installType:null,
            disabled:false,
            isSuccess:false
        };
    },
    mounted() {
        VueEvent.$on("relaodProduct", () => {
            this.reload();
        });
    },
    created() {
        this.productId = this.$route.query.productId;
        this.fetchData(this.productId);
        this.getQuestionsData(
            this.productId,
            this.page.current,
            this.page.size
        );
    },
    methods: {
        closeWpsOrder(){
            this.wpsVisible = false;
            this.isSuccess = false;
            this.installType = null;
            this.purchaseNum = 1;
            this.disabled =false;
        },
        handleSubmit(){
            this.disabled = true;
            if(this.installType == null){
                this.$message({
                    type:'warning',
                    message:'请选择安装方式！'
                })
                this.disabled = false;
            }else{
                savRecord(this.installType,this.productId,this.purchaseNum).then(res => {
                    if(res.success){
                        this.isSuccess = true;
                        setTimeout(()=>{
                            VueEvent.$emit("cooperation");
                        },1500)
                    }else{
                        this.$message({
                            type:'error',
                            message:res.msg
                        })
                        this.disabled = false;
                    }
                })
            }
        },
        choseInstallType(type){
            if(!this.disabled){
                this.installType = type;
            }
        },
        enterCooperation(){
            if (
                this.token == undefined ||
                this.token == "" ||
                this.token == null
            ) {
                this.$message({
                    message: "未登录，去登录后再进行操作！",
                    type: "warning",
                });
            } else {
                this.$router.push('/attestation')
            }
        },
        cooperation(){
            // if(memberType == '0'){
            //     this.visible = true;
            // }else{
            //     this.wpsVisible = true;
            // }
            this.wpsVisible = true;
        },
        getQuestionsData(productId, current, size) {
            getQuestions(productId, current, size).then((res) => {
                if (res.success) {
                    const list = res.body.data.list;
                    if (list.length > 0) {
                        list.forEach((item) => {
                            item["isOpen"] = false;
                        });
                        list[0].isOpen = true;
                        this.questionList = list;
                    }
                }
            });
        },
        fetchData(id) {
            getProductDetails(id).then((res) => {
                if (res.success) {
                    this.details = res.body.data.productInfo;
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.empower-content{
    padding: 0 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    div{
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: #23293a;
        line-height: 30px;
        em{
            font-weight: bold;
            margin-right: 4px;
        }
        span{
            color: #1a4095;
            font-weight: bold;
        }
        i{
            font-style: normal;
            color: #ff8811;
            font-weight: bold;
        }
    }
}
.package-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 226px;
    height: 100%;
    background: -webkit-linear-gradient(#ebf1fb, #f5f6f9);
    /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#ebf1fb, #f5f6f9);
    /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(#ebf1fb, #f5f6f9);
    /* Firefox 3.6 - 15 */
    background: linear-gradient(#ebf1fb, #f5f6f9);
    p{
        font-size: 35px;
        color: #ff6701;
        font-weight: bold;
        margin-right: 6px;
    }
    span{
        color: #252b3a;
        font-size: 20px;
        font-weight: bold;
    }
}
.package-li-box{
    height: 102px;
    border: solid 1px #e6e6e6;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f9fbfe;
    font-family: '思源黑体';
    border: solid 1px #3c7fff;
    margin-bottom: 50px;
}
.empower-box{
    background: #fff;
    .w12{
        >.title{
            height: 166px;
            padding-top: 66px;
            font-size: 35px;
            color: #252b3a;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            p{
                margin: 0 12px;
            }
        }
    }
}
.isSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i{
        font-size: 88px;
        color: #37bd66;
    }
    P{
        margin-top: 14px;
        font-size: 18px;
        color: #353535;
    }
}
.wpsOrderForm{
    padding-top: 10px;
}
.wpsOrderzs{
    margin-top: 26px;
    font-size: 12px;
    line-height: 22px;
    color: #1b4094;
    span{
        color: #ff1111;
    }
}
.wpsOrderType.active{
    border-color: #1b4094;
}
.wpsOrderType{
    width: 110px;
    height: 28px;
    border: solid 1px #d2d2d2;
    margin-right: 12px;
    font-size: 14px;
    color: #353535;
    position: relative;
    cursor: pointer;
    >p{
        width: 100%;
        height: 100%;
        line-height: 28px;
        text-align: center;
    }
    >img{
        position: absolute;
        right: 0;
        bottom: 0;
    }

}
.wpsOrderTypeBox,.wpsOrderType{
    display: flex;
    flex-direction: row;
}
.wpsOrderFormGroup{
    padding: 0 14px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #353535;
    >p{
        width: 110px;
        text-align: right;
        color: #898989;
    }
    >span{
        margin-left: 6px;
    }
    button{
        margin: 0 auto;
        display: block;
        width: 186px;
        height: 38px;
        background: #1b4094;
        font-size: 16px;
        color: #fff;
    }
}
.h1{
    border-bottom: solid 1px #d2d2d2;
}
.wpsOrderTitle{
    padding: 0 14px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
        font-size: 18px;
        color: #353535;
    }
    i{
        font-size: 20px;
        color: #7e7e7e;
        margin-top: -10px;
        cursor: pointer;
    }
}
.wpsOrderBox{
    position: relative;
    padding: 0 14px;
    width: 535px;
    height: 322px;
    background: #fff;
}
#wpsOrderBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0, .5);
}
.cooperationBtn{
    margin: 0 auto;
    text-align: center;
    width: 184px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    background: #ff8610;
    cursor: pointer;
}
.cooperationText{
    margin: 0 auto 24px;
    width: 272px;
    height: 152px;
    border: solid 1px #88abfa;
    background: rgba(42,123,241,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        font-size: 12px;
        line-height: 24px;
        color: #76a1fa;
        text-align: center;
    }
}
.cooperationTips{
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}
.cooperationTitle{
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}
.cooperationBox{
    position: relative;
    width: 535px;
    height: 373px;
    >img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .cooperationContent{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        display: flex;
        flex-direction: column;
        .icon-close{
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            >i{
                font-size: 20px;
                color: #fff;
                margin-right: 22px;
                cursor: pointer;
            }
        }

    }
}
#cooperationBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0, .5);
}
</style>
