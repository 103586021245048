<template>
    <div class="container w12">
        <div class="title">蒙科立综合服务网站服务条款</div>
        <div class="time">2021年12月版</div>
        <div class="ordinaryText">前言</div>
        <div class="ordinaryText">
            欢迎访问蒙科立综合服务网站并使用我们提供的产品和服务。
        </div>
        <div class="ordinaryText indentText">
            在完成注册程序或以任何方式使用蒙科立综合服务网站服务前，
            请您务必仔细阅读并透彻理解本网站服务条款(以下或简称“服务条款”)
            ，在确认充分理解后选择接受或不接受本服务条款；一旦您完成“同意
            条款并注册”或开始以其他方式使用本网站服务，即表明您已阅读并同
            意受本服务条款的约束。如您不同意本服务条款或其中任何条款约定，
            您应不再进行下一步或停止注册程序。
        </div>
        <div class="ordinaryText indentText">
            蒙科立综合服务网站再次提示您审慎阅读、充分理解各条款内容，特别是
            限制或免除责任的相应条款，限制或免除责任条款将以加粗或其他醒目形式提示您注意。
        </div>
        <div class="ordinaryText indentText">
            如果您未满18周岁，请在法定监护人的陪同下阅读本服务条款。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            一、 签约主体以及协议范围
        </div>
        <div class="ordinaryText indentText">
            1.1. 蒙科立综合服务网站服务条款是您与内蒙古蒙科立科技股份有限公司（“蒙科立”或者“我们”）就使用蒙科立综合服务网站服务（包含为您提供网站页面信息浏览、账户注册服务等）而订立的有效合约。
        </div>
        <div class="ordinaryText indentText">
            蒙科立综合服务网站包含域名为www.mklai.cn的网站以及未来发布的蒙科立客户端,如APP。
        </div>
        <div class="ordinaryText indentText">
            1.2. 如您使用或购买蒙科立综合服务网站上某一具体云服务，您可仍需阅读具体服务对应的服务内容及范围；请您审慎阅读、充分理解各条款内容。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            二、账户的注册、使用与安全
        </div>
        <div class="ordinaryText indentText">
            2.1. 注册的资格
        </div>
        <div class="ordinaryText indentText">
            2.1.1. 您确认，在您完成注册程序或以其他蒙科立综合服务网站允许的方式实际使用本服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下或简称为“您”）。
        </div>
        <div class="ordinaryText indentText">
            2.1.2. 若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果。
        </div>
        <div class="ordinaryText indentText">
            2.1.3.您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用蒙科立服务。
        </div>
        <div class="ordinaryText indentText">
            2.2. 账户注册
        </div>
        <div class="ordinaryText indentText">
            2.2.1. 当您按照注册页面提示填写信息、阅读并同意本服务条款且完成全部注册程序后，您可获得蒙科立账户并成为蒙科立综合服务网站的用户。
        </div>
        <div class="ordinaryText indentText">
            2.2.2. 您在注册时候设置或确认的账户名（“账户名称”）及您设置的密码，将在注册成功后成为您的账户（账户名称及密码合称“账户”）。
        </div>
        <div class="ordinaryText indentText">
            2.2.3. 您设置的账户名不得违反国家法律法规、蒙科立综合服务网站的管理规范或容易引起您与蒙科立综合服务网站身份的混淆，
            否则您的账户可能不能注册成功或本网站有权经通知您后予以注销。
        </div>
        <div class="ordinaryText indentText">
            2.2.4. 您应当按照法律法规要求或按相应页面的提示，准确提供并及时更新您的账户信息，以使之真实、及时、完整和准确。
            如您提供的资料错误、不实、过时或不完整的，蒙科立综合服务网站可以向您发出询问及/或要求改正的通知，您应按照蒙科
            立综合服务网站的要求配合提供或者更新相关资料。因您填写的信息、资料不真实、不及时、不完整或不准确的，您应承担您
            不能使用蒙科立综合服务网站账户（不能注册成功、或账户被冻结、注销）或您在使用过程中的后果和损失。
        </div>
        <div class="ordinaryText indentText">
            2.2.5. 通常情况下，您的蒙科立综合服务账户是您在蒙科立综合服务网站进行一切活动的唯一身份标识，除非另有约定，
            每一个蒙科立综合服务账户都可以在本网站独立开展活动。
        </div>
        <div class="ordinaryText indentText">
            2.3. 账户的使用和安全
        </div>

        <div class="ordinaryText indentText">
            2.3.1. 您有权使用您设定的账户登录蒙科立综合服务网站，进而使用蒙科立综合服务网站提供的其他服务。
        </div>
        <div class="ordinaryText indentText">
            2.3.2. 蒙科立综合服务网站可能会以邮件、站内信、短信或电话等方式通知您服务进展情况以及提示您进行下
            一步的操作。在服务过程中您应当及时登录到蒙科立综合服务账户查看和进行交易操作。
        </div>
        <div class="ordinaryText indentText">
            2.3.3. 一个蒙科立综合服务账户仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经蒙科立同意
            的情况下，您不得以任何方式转让、赠与或让他人继承您的蒙科立综合服务账户。
        </div>
        <div class="ordinaryText indentText">
            2.3.4. 您的账户和密码由您自行设置并由您保管，您须对您的蒙科立综合服务账户和密码保密。您需确保您在每个上网时段结束时，以正确步骤离开网站。
        </div>
        <div class="ordinaryText indentText">
            2.3.5. 如发现他人未经授权使用您的蒙科立综合服务账户和密码，您应立即通知我们；蒙科立将协助您冻结账户
            、更改密码或进行其他安全设置；您理解蒙科立综合服务对您的请求采取行动需要合理时间，蒙科立综合服务网站
            对在采取行动前已经产生的以及由您引发的后果（包括但不限于您的任何损失）不承担任何责任。
        </div>
        <div class="ordinaryText indentText">
            2.3.6. 蒙科立亦会在网站服务端采取合理的技术措施保障账户的安全。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            三、账户的冻结、注销及申诉
        </div>
        <div class="ordinaryText indentText">
            3.1. 账户的冻结
        </div>
        <div class="ordinaryText indentText">
            您的蒙科立综合服务账户（全部或部分权限或功能）在如下情况可能被冻结（如帐户被限制使用产品服务功能等），蒙科立综合服务网站将通过邮件、站内信、短信或电话等方式通知您：
        </div>
        <div class="ordinaryText indentText">
            3.1.1. 基于蒙科立综合服务网站或服务运行和交易安全的需要，如您发生或可能发生破坏或试图破坏蒙科立综合服务
            网站或蒙科立关联公司公平交易环境或正常交易秩序的，或任何使用含有蒙科立或蒙科立关联公司名称、品牌且对他
            人有误导嫌疑或任何使用某种中英文(全称或简称)、数字、域名等意图表示或映射与蒙科立或其关联公司具有某种关联的；
        </div>
        <div class="ordinaryText indentText">
            3.1.2. 违反本服务条款、蒙科立综合服务网站的相关规则、规范（如交易规则、管理规范）、服务说明以及其他服务协议/条款的；
        </div>
        <div class="ordinaryText indentText">
            3.1.3.违反国家法律、法规、政策、法律文书的规定的；
        </div>
        <div class="ordinaryText indentText">
            3.1.4. 您遭到他人投诉， 且对方已经提供了相关证据的，而您未按照我们的要求提供相反证据的；
        </div>
        <div class="ordinaryText indentText">
            3.1.5. 蒙科立综合服务网站根据合理分析判断您的账户操作、收益、兑换等存在异常的；
        </div>
        <div class="ordinaryText indentText">
            3.1.6. 国家有权机关要求进行的冻结的；
        </div>
        <div class="ordinaryText indentText">
            3.1.7. 蒙科立综合服务网站合理判断，您发生与如上行为性质相同或产生如上类似风险的其他情况的。
        </div>
        <div class="ordinaryText indentText">
            3.2. 账户的注销
        </div>
        <div class="ordinaryText indentText">
            3.2.1. 如果出现如上第3.1条情形且情形严重的，或基于国家有权机关的要求，您的蒙科立综合服务账户（全部或部分权限或功能）
            将被注销，蒙科立将通过邮件、站内信、短信或电话等方式通知您。
        </div>
        <div class="ordinaryText indentText">
            3.2.2. 您理解并同意，如您连续12个月未通过账户登录过蒙科立综合服务网站，且您的账户下不存在任何未到期的服务，蒙科立综合服务网站有权注销您的账户，您将不能再登录蒙科立综合服务网站。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            四、网站服务及规范
        </div>
        <div class="ordinaryText indentText">
            4.1. 您有权享受蒙科立通过本网站提供的互联网技术服务和信息服务。您同时还应根据本服务条款以及您在购买具体服务时确认的相关条款和条件，履行及时付款、服务管理等责任。
        </div>
        <div class="ordinaryText indentText">
            4.2. 您使用蒙科立综合服务网站的服务，您应保证：
        </div>
        <div class="ordinaryText indentText">
            4.2.1. 您使用蒙科立综合服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用蒙科立综合服务网站提供的服务进行存储、发布、传播如下信息和内容：
        </div>
        <div class="ordinaryText indentText2">
            （ⅰ）违反国家法律法规政策的任何内容（信息）；
        </div>
        <div class="ordinaryText indentText2">
            （ⅱ）违反国家规定的政治宣传和/或新闻信息；
        </div>
        <div class="ordinaryText indentText2">
            （ⅲ）涉及国家秘密和/或安全的信息；
        </div>
        <div class="ordinaryText indentText2">
            （ⅳ）封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；
        </div>
        <div class="ordinaryText indentText2">
            （ⅴ）博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；
        </div>
        <div class="ordinaryText indentText2">
            （ⅵ）妨碍互联网运行安全的信息；
        </div>
        <div class="ordinaryText indentText2">
            （ⅶ）侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；
        </div>
        <div class="ordinaryText indentText2">
            （ⅷ）您同时承诺不得为他人发布上述不符合国家规定和/或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等；
        </div>
        <div class="ordinaryText indentText">
            4.2.2. 不应出现任何破坏或试图破坏网络安全等的行为，包括不会利用技术或其他手段破坏或扰乱本网站及蒙科立其他客户的网站；
        </div>
        <div class="ordinaryText indentText">
            4.2.3. 您使用蒙科立综合服务网站的服务应符合本服务条款；
            如您违反上述保证，蒙科立除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权冻结或注销您账户部分或全部功能。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            五、隐私及个人信息的保护
        </div>
        <div class="ordinaryText indentText">
            您的信任对蒙科立非常重要，蒙科立深知用户信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。具体详见<br/>
            <span class="pointer" @click="enterPrucacy">《隐私政策》</span>。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            六、知识产权
        </div>
        <div class="ordinaryText indentText">
            6.1. 我们尊重知识产权，除非获得了您的同意，蒙科立不会擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表您拥有知识产权的成果。
        </div>
        <div class="ordinaryText indentText">
            6.2. 除非另行说明，蒙科立综合服务网站Logo、“蒙科立”、“mklai”等文字、图形及其组合，以及蒙科立综合服务网站的其他标识、徽记、蒙科立综合
            服务的名称、技术文档等的知识产权为蒙科立及其关联公司所有。
        </div>
        <div class="ordinaryText indentText">
            6.3. 您应尊重蒙科立和第三方的知识产权和其他合法权利/权益，并保证在发生侵犯前述权益的违法事件时，保护蒙科立及其雇员、股东
            、合作伙伴等免于因该等事件受到影响或损失，蒙科立保留在您侵犯蒙科立及/或其他第三方的合法权利/权益时终止向您提供服务并不退还任何款项的权利。
        </div>
        <div class="ordinaryText indentText">
            其他具体信息详见<span class="pointer"  @click="enterPrucacy">《隐私政策》</span>的法律声明部分。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            七、保密
        </div>
        <div class="ordinaryText indentText">
            蒙科立承诺对您注册账户时或使用蒙科立综合服务时提交或知悉的信息采取保密措施，不向第三方披露您的信息，除非：
        </div>
        <div class="ordinaryText indentText">
            7.1. 依据本服务条款或者您与蒙科立之间其他服务协议、合同、在线条款等规定可以提供的；
        </div>
        <div class="ordinaryText indentText">
            7.2. 依据法律法规的规定或行政、司法等职权部门要求应当提供的；
        </div>
        <div class="ordinaryText indentText">
            7.3. 在不违反本服务条款约定责任的前提下，该保密信息已经公开或能从公开领域获得。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            八、出口管制
        </div>
        <div class="ordinaryText indentText">
            双方承诺遵守本协议所适用的联合国、中国、美国以及其他国家出口管制法律法规。您承诺不会将蒙科立提供的产品或服务
            用于适用的出口管制法律法规禁止的用途。非经相关主管机关许可，您及您授权使用蒙科立产品或服务的其他个人或实体不
            会通过蒙科立产品或服务向适用的出口管制法律法规禁止的实体或个人提供受管控的技术、软件或服务。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            九、责任范围和责任限制
        </div>
        <div class="ordinaryText indentText">
            9.1. 您了解并同意，您应承担因您使用本服务、违反本服务条款或在您的账户下采取的任何行动而导致的任何第三方索赔。
            如果由此引起蒙科立及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿蒙科立及其关联公司
            由此遭受的全部损失和责任。
        </div>
        <div class="ordinaryText indentText">
            9.2. 在适用法律许可的范围内，蒙科立不对与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。
        </div>
        <div class="ordinaryText indentText">
            9.3. 蒙科立在此提示，您在使用蒙科立服务期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用蒙科立的服务从事侵犯他人名誉、
            隐私、知识产权和其他合法权益的活动。蒙科立不对您使用蒙科立服务的违法或违约行为承担任何责任。
        </div>
        <div class="ordinaryText indentText">
            9.4. 如果您通过使用蒙科立综合服务网站或获取来自第三方的任何产品或服务，例如，蒙科立综合服务网站的第三方服务商依托本站向您提供服务，
            服务商将对其提供的服务向您负责，蒙科立对此第三方服务不承担任何责任。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            十、不可抗力和意外事件
        </div>
        <div class="ordinaryText indentText">
            蒙科立综合服务的网站可能因为下列不可抗力或意外事件无法正常运行的，蒙科立不承担损害赔偿责任：
        </div>
        <div class="ordinaryText indentText">
            10.1. 因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
        </div>
        <div class="ordinaryText indentText">
            10.2. 因电力供应故障、通讯网络故障等公共服务因素；
        </div>
        <div class="ordinaryText indentText">
            10.3. 经提前公告或通知的，蒙科立在短时间内的系统维护。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            十一、 法律适用与管辖
        </div>
        <div class="ordinaryText indentText">
            本服务条款之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本服务条款产生之争议应提交至内蒙古自治区呼和浩特市赛罕区人民法院解决。
        </div>
        <div class="ordinaryText indentText"></div>
        <div class="ordinaryText indentText">
            十二、通知送达
        </div>
        <div class="ordinaryText indentText">
            13.1. 蒙科立有权对本服务条款及相应的服务规则内容进行变更，并将网页公告、电子邮件、站内信、短信等方式予以公告或通知；若您在本服务条款内容变更后
            继续使用本服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的条款内容。
        </div>
        <div class="ordinaryText indentText">
            13.2. 在您的账户注销或经双方协商一致终止网站服务的，本服务条款终止。
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        enterPrucacy(){
            this.$router.push('/privacy')
        }
    }
};
</script>
<style lang="less" scoped>
.indentText2{
    text-indent: 64px;
}
.indentText {
    text-indent: 32px;
}
.ordinaryText {
    font-size: 16px;
    color: #333;
    line-height: 32px;
    min-height: 32px;
    p {
        margin-bottom: 10px;
    }
    span{
        color: #1b4094;
    }
}
.time {
    font-size: 20px;
    text-align: right;
}
.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
}
.container {
    padding: 20px;
    background: #fff;
}
</style>
