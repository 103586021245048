<template>
    <div
        class="tab-bar-item"
        @click="itemClick"
        @mouseenter="itemHover"
        @mouseleave="itemLeave"
    >
        <div :style="isStyleActive" class="nav">
            <div>
                <slot name="item-text"></slot>
                <span :class="{ activeIcon: isActive }"></span>
            </div>
            <div class="freeProduct" :style="freeProduct" v-if="path=='/app-conversion' || path=='/app-proof' || path=='/app-convert-mongolia' || path=='/app-picture-create'"></div>
            <p v-if="isActive || isShow"></p>
        </div>
    </div>
</template>

<script>
import { PcCookie, Key } from "@/utils/cookie";
export default {
    name: "TarBarItem",
    props: {
        path: {
            type: String,
        },
        activeStyle: {
            type: String,
            default: "#1b4094",
        },
        productId:{
            type: String,
            default: '',
        }
    },
    data() {
        return {
            isShow:false,
            token: PcCookie.get(Key.tokenKey),
            freeProduct: {
                backgroundImage:'url(' + require('@/assets/images/product/freeProductImg.png') + ')',
                backgroundRepeat:'no-repeat',
                backgroundSize: '100% 100%'
            }
        };
    },
    methods: {
        itemClick() {
            console.log(this.path);
            if (
                this.path == "/app-menkocr" ||
                this.path == "/app-translate" ||
                this.path == "/app-recognition" ||
                this.path == "/app-synthesis"
            ) {
                if (
                    this.token == undefined ||
                    this.token == "" ||
                    this.token == null
                ) {
                    this.$message({
                        message: "未登录，去登录后再使用！",
                        type: "warning",
                    });
                } else {
                    this.$router.push({
                        path:this.path,
                        query:{
                            productId:this.productId
                        }
                    });
                }
            } else {
                if(this.productId == ''){
                    this.$router.push({
                        path:this.path,
                    });
                }else{
                    this.$router.push({
                        path:this.path,
                        query:{
                            productId:this.productId
                        }
                    });
                }
            }
            // if (
            //     this.token == undefined ||
            //     this.token == "" ||
            //     this.token == null
            // ) {
            //     this.$message({
            //         message: "未登录，去登录后再使用！",
            //         type: "warning",
            //     });
            // } else {
            //     if(this.productId == ''){
            //         this.$router.push({
            //             path:this.path,
            //         });
            //     }else{
            //         this.$router.push({
            //             path:this.path,
            //             query:{
            //                 productId:this.productId
            //             }
            //         });
            //     }

            // }
        },
        itemHover() {
            this.isShow = true;
        },
        itemLeave() {
            this.isShow = false;
        },
    },
    computed: {
        isActive() {
            return this.$route.path.indexOf(this.path) !== -1;
        },
        isStyleActive() {
            return this.isActive ? { color: this.activeStyle } : {};
        },
    },
};
</script>

<style scoped lang="less">
.freeProduct{
    position: absolute;
    right: -10px;
    top: 8px;
    width: 24px;
    height: 16px;
}
.companyNews {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
    .c-news {
        padding: 36px 0;
        width: 232px;
        height: 100%;
        .title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 33px;
            border-bottom: solid 1px #e6e6e6;
            margin-bottom: 10px;
            > p {
                color: #1b4094;
                font-size: 16px;
            }
        }
        .c-news-li {
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: #252b3a;
            text-align: left;
        }
        .c-news-li:hover {
            color: #1b4094;
        }
        .c-news-li-p {
            height: 88px;
            border: solid 1px #e6e6e6;
            overflow: hidden;
            margin: 30px 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.aboutUs {
    display: flex;
    flex-direction: row;
    height: 340px;
    .companyIntroduction {
        width: 320px;
        height: 100%;
        border-right: solid 1px #e6e6e6;
        position: relative;
        overflow: hidden;
        .buildingImg {
            position: absolute;
            left: 0;
            bottom: 0;
        }
        .companyBox {
            padding: 0 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            z-index: 99;
            background: rgba(255, 255, 255, 0.9);
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            p {
                margin-bottom: 12px;
                color: #252b3a;
                font-weight: bold;
                line-height: 32px;
                text-align: left;
            }
            span {
                margin-bottom: 8px;
                color: #6f7583;
                line-height: 24px;
                text-align: left;
            }
            i {
                color: #37539c;
                line-height: 32px;
                font-style: normal;
                text-align: left;
                margin: 0;
            }
        }
    }
}
.item-second-nav {
    position: absolute;
    top: 72px;
    background: rgba(255, 255, 255, 1);
    z-index: 999;
    border: solid 1px #e6e6e6;
    box-shadow: -3px 4px 5px 0 rgb(102 128 158 / 40%), -2px 2px 0 0 #fff;
}
.activeIcon i {
    color: #1b4094 !important;
}
.tab-bar-item i {
    margin-left: 4px;
    font-size: 14px;
    color: #252b3a;
}
.tab-bar-item {
    margin-right: 26px;
    position: relative;
    text-align: center;
    height: 73px;
    font-size: 14px;
    color: #252b3a;
    cursor: pointer;
    .nav {
        line-height: 73px;
    }
}
.nav p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #1b4094;
}
</style>
