var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactUs"},[_c('div',{staticClass:"w12 h100"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"contactUsMap"},[_c('baidu-map',{staticClass:"bMap",attrs:{"center":_vm.center,"zoom":_vm.zoom},on:{"ready":_vm.handler}},[_c('bm-marker',{attrs:{"position":{ lng: 111.776614, lat: 40.849223 },"dragging":true,"animation":"BMAP_ANIMATION_BOUNCE"}},[_c('bm-label',{attrs:{"content":"呼和浩特市赛罕区机场高速路128号<br>低碳大厦7层 010011","labelStyle":{
                            color: 'back',
                            fontSize: '16px',
                            border: 0,
                            padding: '10px 20px',
                        },"offset":{ width: -90, height: -80 }}})],1)],1)],1),_c('div',{staticClass:"workTime"},[_vm._v(" 办公时间：周一至周五 08:30-11:50 13:00-17:30 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',{staticClass:"menkTitle"},[_vm._v(""),_c('br'),_vm._v(" "),_c('br'),_vm._v("")]),_c('span',[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-content"},[_c('div',{staticClass:"contact-text h100"},[_c('img',{attrs:{"src":require("assets/images/contactImg1.png"),"alt":""}}),_c('p',[_vm._v("联系电话")]),_c('p',[_vm._v("0471-6929536 (服务中心)")])]),_c('div',{staticClass:"contact-text h100"},[_c('img',{attrs:{"src":require("assets/images/contactImg2.png"),"alt":""}}),_c('p',[_vm._v("电子邮箱")]),_c('img',{staticStyle:{"margin-top":"2px"},attrs:{"src":require("assets/images/aboutEmailImg.png"),"alt":""}})]),_c('div',{staticClass:"contact-text h100"},[_c('img',{attrs:{"src":require("assets/images/contactImg3.png"),"alt":""}}),_c('p',[_vm._v("QQ")]),_c('p',[_vm._v("391059028")])]),_c('div',{staticClass:"contact-text h100"},[_c('img',{attrs:{"src":require("assets/images/contactImg4.png"),"alt":""}}),_c('img',{attrs:{"src":require("assets/images/codeImg.png"),"alt":""}})])])
}]

export { render, staticRenderFns }